/// <reference path="../../references.d.ts"/>
var AdminService = (function () {
    function AdminService($resourceBuilder) {
        this.parameterResource = $resourceBuilder.build('parameter');
        this.defaultParameterResource = $resourceBuilder.build('parameter/default');
    }
    AdminService.prototype.getParameterResource = function () {
        return this.parameterResource;
    };
    AdminService.prototype.getDefaultParameterResource = function () {
        return this.defaultParameterResource;
    };
    AdminService.prototype.toBooleanMap = function (arr) {
        var returnMap = {};
        if (arr) {
            arr.forEach(function (str) {
                returnMap[str] = true;
            });
        }
        return returnMap;
    };
    AdminService.prototype.toStringArray = function (map) {
        var returnArray = [];
        if (map) {
            Object.keys(map).forEach(function (key) {
                if (map[key]) {
                    returnArray.push(key);
                }
            });
        }
        return returnArray;
    };
    return AdminService;
}());
angular.module('app').service('AdminService', AdminService);
