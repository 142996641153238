///<reference path="../../references.d.ts"/>
var User = (function () {
    function User() {
        this.dailyNotification = false;
        this.reportFormats = [];
        this.clients = [];
    }
    return User;
}());
var UserForm = (function () {
    function UserForm() {
        this.reportFormats = [];
        this.bdrs = [];
        this.controlThatUserBelongsToClient = true;
    }
    return UserForm;
}());
var UserService = (function () {
    function UserService($resourceBuilder, SupportService) {
        this.supportService = SupportService;
        this.userResource = $resourceBuilder.build('user/:email');
        this.settingResource = $resourceBuilder.build('user/settings');
    }
    UserService.prototype.getUserResource = function () {
        return this.userResource;
    };
    UserService.prototype.getSettingResource = function () {
        return this.settingResource;
    };
    UserService.prototype.getCurrent = function (successCallback, errorCallback) {
        var selectedUser = this.supportService.getSelectedUser();
        if (selectedUser) {
            return this.userResource.get({ 'email': selectedUser }, successCallback, errorCallback);
        }
        return this.userResource.get({ 'email': 'current' }, successCallback, errorCallback);
    };
    UserService.prototype.newUser = function () {
        return new User();
    };
    UserService.prototype.newUserForm = function () {
        return new UserForm();
    };
    UserService.prototype.userFormFromUser = function (user) {
        var userForm = this.newUserForm();
        if (user) {
            userForm.email = user.email;
            user.clients.forEach(function (client) { return userForm.bdrs.push(client.bdr); });
            userForm.dailyNotification = user.dailyNotification;
            userForm.reportFormats = user.reportFormats;
        }
        return userForm;
    };
    return UserService;
}());
angular.module('app').service('UserService', UserService);
