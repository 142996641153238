///<reference path="../../references.d.ts"/>

/**
 * directive used to collapse mobile-size bootstrap menus by simulating a click on the toggle button
 * 
 * usage : <a menu-collapser> </a>
 */
interface EzUnfluidifier extends ng.IScope {
  fileread: File;
}

angular.module('app')
  .directive('ezUnfluidifier', (): ng.IDirective => {
    return {
      restrict: 'A',
      link: function (scope: EzUnfluidifier, element: ng.IAugmentedJQuery, attributes: ng.IAttributes, formController: ng.IFormController) {
        $(element).find('nav div.container-fluid').removeClass('container-fluid').addClass('container');
      }
    };
  });
