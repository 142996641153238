///<reference path="../../references.d.ts"/>
var Client = (function () {
    function Client() {
        this.quoteTimes = [];
        this.reportFormats = [];
        this.instruments = [];
    }
    return Client;
}());
var ClientId = (function () {
    function ClientId() {
    }
    return ClientId;
}());
var ClientService = (function () {
    function ClientService($resourceBuilder, extendedHttpService) {
        this._$http = extendedHttpService;
        this.clientResource = $resourceBuilder.build('client/:bdr');
    }
    ClientService.prototype.getClientResource = function () {
        return this.clientResource;
    };
    ClientService.prototype.getClientsByInstrument = function (instrument) {
        return this._$http.get('client/instrument/' + instrument.name);
    };
    ClientService.prototype.getClientIcId = function (bdr) {
        return this._$http.get("client/ic/id/" + bdr, { responseType: 'text' });
    };
    ClientService.prototype.getNowAsString = function (datePattern) {
        return this._$http.get("client/now/" + datePattern, { responseType: 'text' });
    };
    ClientService.prototype.newClient = function () {
        return new Client();
    };
    ClientService.prototype.newClientId = function () {
        return new ClientId();
    };
    return ClientService;
}());
angular.module('app').service('ClientService', ClientService);
