///<reference path="../../references.d.ts"/>


class InstrumentRefSet implements IInstrumentRefSet {
  id: number;
  source: string;
  place: string;
  displayedName: string;
  field: string;
  quoteType: string;
}

class InstrumentRefSetService implements IInstrumentRefSetService {
  private clientResource: ng.resource.IResourceClass<ng.resource.IResource<IInstrumentRefSet>>;
  _$http: ng.IHttpService;

  constructor($resourceBuilder, extendedHttpService: ng.IHttpService) {
    this._$http = extendedHttpService;
    this.clientResource = $resourceBuilder.build('instrument-refset');
  }

  getAvailableRefSets(): ng.IHttpPromise<IInstrumentRefSet[]> {
    return this._$http.get('instrument-refset');
  }

  getInstrumentRefSetResource(): ng.resource.IResourceClass<ng.resource.IResource<IInstrumentRefSet>> {
    return this.clientResource;
  }
}
angular.module('app').service('InstrumentRefSetService', InstrumentRefSetService);
