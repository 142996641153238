///<reference path="../../../references.d.ts"/>

class SnapshotPickerController {
  ngData: ISnapshot[]; // list of snapshot sent by parent
  ngModel: string;  // Return value container (selected Snapshot Id)

  // Data Model
  selectedDateMidnight: Date; // the date selected in date-picker. /!\ always the date at midnight. ie: 2017-12-31T00:00:00.000Z
  snapshotsByStrDate: { [key: string]: ISnapshot[] }; // map of snapshot by date with no time as string. ie: 2017-12-31
  availableSnapshots: ISnapshot[]; // Snapshots available for a selected date
  selectedSnapshot: ISnapshot;

  // GUI Model
  startDateTime: Date;
  endDateTime: Date;

  constructor($location: ng.ILocationService, $scope: ng.IScope) {

    $scope.$watch(() => { return this.ngData; }, () => {
      this.snapshotsByStrDate = {};
      if (!this.ngData) {
        return;
      }

      // A snapshot.snapshotDateTime is a date in ISO String so they are sortable without type convertion. Init 2 string to find min/max date.
      let minStrDateTime = '3999-31-12T23:59:59.99Z';
      let maxStrDateTime = '';

      this.ngData.forEach((snapshot: ISnapshot) => {
        // Find oldest available date
        if (snapshot.snapshotDateTime < minStrDateTime) {
          minStrDateTime = snapshot.snapshotDateTime;
        }
        // Find newest available date
        if (snapshot.snapshotDateTime > maxStrDateTime) {
          maxStrDateTime = snapshot.snapshotDateTime;
          // last snapshot will be the selected in date-picker
          this.selectedSnapshot = snapshot;
        }
        // Build snapshots Model
        let strDate: string = snapshot.snapshotDateTime.substr(0, 10);
        if (!this.snapshotsByStrDate[strDate]) {
          this.snapshotsByStrDate[strDate] = [];
        }
        this.snapshotsByStrDate[strDate].push(snapshot);
      });

      // Init date-picker dateTime range
      this.startDateTime = new Date(minStrDateTime);
      this.endDateTime = new Date(maxStrDateTime);
      // Init selected date in date-picker
      this.selectedDateMidnight = new Date(maxStrDateTime.substr(0, 10));
    });

    $scope.$watch(() => { return this.selectedDateMidnight; }, () => {
      if (this.selectedDateMidnight) {
        this.availableSnapshots = this.snapshotsByStrDate[this.selectedDateMidnight.toISOString().substr(0, 10)];
      }
    });
  }

  public selectSnapshot(snapshot: ISnapshot) {
    this.ngModel = snapshot.id;
    this.selectedSnapshot = snapshot;
  }

}

angular.module('app')
  .directive('snapshotPicker', (): ng.IDirective => {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'snapshotPicker.html',
      scope: {},
      bindToController: {
        ngData: '=',
        ngModel: '='
      },
      controllerAs: 'snapCtl',
      controller: SnapshotPickerController
    };
  });
