///<reference path="../../../references.d.ts"/>
var CheckboxModel = (function () {
    function CheckboxModel() {
        this.base = {};
        this.quote = {};
    }
    return CheckboxModel;
}());
var CcyPickerController = (function () {
    function CcyPickerController(AdminService, $location, $scope) {
        var _this = this;
        // default base currencies
        this.baseCurrenciesValues = ['EUR', 'CHF', 'GBP'];
        this.adminService = AdminService;
        // When new instrument list is passed to the directive
        // Initialise ccyPicker Model
        $scope.$watch(function () { return _this.ngData; }, function () {
            _this.baseCurrencies = [];
            _this.quoteCurrencies = [];
            _this.availablePairs = [];
            _this.checkboxModel = new CheckboxModel();
            if (_this.ngData) {
                // Init Reference Model
                _this.ngData.forEach(function (instrument) {
                    var base = instrument.name.substring(0, 3);
                    var quote = instrument.name.substring(3, 6);
                    if (_this.baseCurrencies.indexOf(base) < 0) {
                        _this.baseCurrencies.push(base);
                    }
                    if (_this.quoteCurrencies.indexOf(quote) < 0) {
                        _this.quoteCurrencies.push(quote);
                    }
                    _this.availablePairs.push(instrument.name);
                });
                if (_this.creationMode) {
                    _this.setDefaultSettings();
                }
                else {
                    _this.originalPairs = _this.ngModel;
                    if (_this.ngModel) {
                        _this.ngModel.forEach(function (instrument) {
                            var base = instrument.substring(0, 3);
                            var quote = instrument.substring(3, 6);
                            _this.checkboxModel.base[base] = true;
                            _this.checkboxModel.quote[quote] = true;
                        });
                    }
                }
            }
        });
        // Apply checkboxModel to ngModel every time it changes
        $scope.$watch(function () { return _this.checkboxModel; }, function () {
            // adapt checkboxModel
            _this.showMissingCurrencyMessage = false;
            _this.quoteCurrencies.forEach(function (quoteCur) {
                if (!_this.isQuoteCurrencyAvailable(quoteCur)) {
                    _this.checkboxModel.quote[quoteCur] = false;
                }
            });
            // apply checkboxModel
            _this.ngModel = [];
            _this.ngData.forEach(function (instrument) {
                var base = instrument.name.substring(0, 3);
                var quote = instrument.name.substring(3, 6);
                if (_this.checkboxModel.base[base] && _this.checkboxModel.quote[quote]) {
                    _this.ngModel.push(instrument.name);
                }
            });
            /* Generate analysis messages model */
            if (_this.originalPairs) {
                // Find added pairs
                _this.pairsToBeAdded = [];
                _this.ngModel.forEach(function (pair) {
                    if (_this.originalPairs.indexOf(pair) < 0) {
                        _this.pairsToBeAdded.push(pair);
                    }
                });
                // Find removed pairs
                _this.pairsToBeRemoved = [];
                _this.originalPairs.forEach(function (pair) {
                    if (_this.ngModel.indexOf(pair) < 0) {
                        _this.pairsToBeRemoved.push(pair);
                    }
                });
            }
        }, true);
    }
    CcyPickerController.prototype.selectAll = function () {
        var _this = this;
        this.checkboxModel = new CheckboxModel;
        this.baseCurrencies.forEach(function (baseCur) {
            _this.checkboxModel.base[baseCur] = true;
        });
        this.quoteCurrencies.forEach(function (quoteCur) {
            _this.checkboxModel.quote[quoteCur] = true;
        });
    };
    CcyPickerController.prototype.setDefaultSettings = function () {
        var _this = this;
        this.checkboxModel = new CheckboxModel;
        this.baseCurrencies.forEach(function (baseCur) {
            _this.checkboxModel.base[baseCur] = _this.baseCurrenciesValues.indexOf(baseCur) >= 0;
        });
        this.quoteCurrencies.forEach(function (quoteCur) {
            _this.checkboxModel.quote[quoteCur] = true;
        });
    };
    CcyPickerController.prototype.selectNone = function () {
        this.checkboxModel = new CheckboxModel;
    };
    CcyPickerController.prototype.isQuoteCurrencyAvailable = function (quote) {
        var _this = this;
        return Object.keys(this.checkboxModel.base).every(function (baseCur) {
            if (_this.checkboxModel.base[baseCur] && quote !== baseCur) {
                if (_this.availablePairs.indexOf(baseCur + quote) < 0) {
                    _this.showMissingCurrencyMessage = true;
                    return false;
                }
            }
            return true;
        });
    };
    return CcyPickerController;
}());
angular.module('app')
    .directive('ccyPicker', function () {
    return {
        restrict: 'EA',
        replace: true,
        templateUrl: "client/directives/ccyPicker/ccyPicker.html",
        scope: {},
        bindToController: {
            ngData: '=',
            ngModel: '=',
            creationMode: '='
        },
        controllerAs: 'ccy',
        controller: CcyPickerController
    };
});
