///<reference path="../../references.d.ts"/>

/**
 * directive used to automatically formats a number in the <input> 
 */
angular.module('app')
  .directive('format', ($filter: ng.IFilterService): ng.IDirective => {
    return {
      require: '?ngModel',
      link: function (scope: ng.IScope, elem: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: ng.IFormController) {
        if (!ctrl) {
          return;
        }
        ctrl.$formatters.unshift(() => {
          return $filter(attrs.format)(ctrl.$modelValue);
        });
        ctrl.$parsers.unshift((viewValue: string) => {
          if (viewValue) {
            let plainNumber = viewValue.replace(/[^0-9.]/g, '');
            let myDecimals = plainNumber.match(/\.\d{0,2}/);
            if (myDecimals) {
              plainNumber = plainNumber.slice(0, plainNumber.indexOf('.'));
            }
            let formatted = $filter(attrs.format)(plainNumber);
            if (myDecimals) {
              formatted += myDecimals[0];
              plainNumber += myDecimals[0];
            }
            elem.val(formatted);
            return plainNumber;
          }
          else {
            return '';
          }
        });
      }
    };
  });
