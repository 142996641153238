///<reference path="../../../../references.d.ts"/>
/**
 * Account Configuration tab controller
 */
var AccountConfigurationController = (function () {
    function AccountConfigurationController(ClientService, InstrumentService, AdminService, NotificationService, $scope, $i18next, $appFileSaver) {
        var _this = this;
        this.accountValidationMsg = '';
        this._$scope = $scope;
        this._$i18next = $i18next;
        this.clientService = ClientService;
        this.instrumentService = InstrumentService;
        this.adminService = AdminService;
        this.notificationService = NotificationService;
        this.appFileSaver = $appFileSaver;
        this.clients = this.clientService.getClientResource().query({ 'full': true }, function () {
            _this.adminService.getParameterResource().get(function (result) {
                var params = result;
                _this.availableEmailTemplates = params.emailTemplates;
                _this.availableFormats = params.reportFormats;
                _this.availableQuoteTimes = params.quoteTimes;
                _this.loaded = true;
            }, function () {
                _this.notificationService.add(_this._$i18next('common:error'), _this._$i18next('admin:account.cannotRetrieveParameters'), 'danger', 5000);
                _this.loaded = true;
            });
        }, function (error) {
            _this.notificationService.add(_this._$i18next('common:error'), _this._$i18next('admin:account.cannotRetrieveAccountList'), 'danger', 5000);
            _this.loaded = true;
        });
        // TODO Refactor to load instruments once for the whole admin page
        this.availableInstruments = this.instrumentService.getInstrumentResource().query(function () { }, function (error) {
            _this.notificationService.add(_this._$i18next('common:error'), _this._$i18next('admin:account.cannotRetrieveInstrumentList'), 'danger', 5000);
        });
        this.adminService.getDefaultParameterResource().get(function (result) {
            _this.defaultParameters = result;
        }, function () {
            _this.notificationService.add(_this._$i18next('common:error'), _this._$i18next('admin:account.cannotRetrieveDefaultParamList'), 'danger', 5000);
            _this.loaded = true;
        });
    }
    AccountConfigurationController.prototype.showInstruments = function (client, event) {
        this.selectedClient = client;
        this.selectedInstruments = this.buildInstrumentsMap(this.selectedClient.instruments);
        this.modalShowInstruments.show(event);
    };
    AccountConfigurationController.prototype.buildInstrumentsMap = function (instruments) {
        var instrumentMap = {};
        this.selectedClient.instruments.forEach(function (instrument) {
            var pivot = instrument.substring(0, 3);
            // Build margedQuotes Map
            if (!instrumentMap[pivot]) {
                instrumentMap[pivot] = [];
            }
            instrumentMap[pivot].push(instrument);
        });
        return instrumentMap;
    };
    // Account edition methods
    AccountConfigurationController.prototype.createAccount = function (event) {
        this.selectedClient = null;
        this.editedClient = this.clientService.newClient();
        this.editedQuoteTimes = {};
        this.editedFormats = {};
        this.editedFormats = this.adminService.toBooleanMap(this.defaultParameters.reportFormats);
        this.editedQuoteTimes = this.adminService.toBooleanMap(this.defaultParameters.quoteTimes);
        this.editedReportName = 'SG Daily currency prices for <yyyyMMdd>';
        this.formatReportName();
        this.modalEditAccountCreateMode = true;
        this.modalEditAccountShowCcy = false;
        this.modalEditAccount.show(event);
    };
    AccountConfigurationController.prototype.editAccount = function (client, event) {
        delete client.$rowConfig;
        this.selectedClient = client;
        this.editedClient = angular.copy(this.selectedClient);
        // Initialize checkbox model for modals
        this.editedQuoteTimes = this.adminService.toBooleanMap(this.editedClient.quoteTimes);
        this.editedFormats = this.adminService.toBooleanMap(this.editedClient.reportFormats);
        this.editedReportName = this.editedClient.reportName;
        this.formatReportName();
        this.modalEditAccountCreateMode = false;
        this.modalEditAccountShowCcy = false;
        this.modalEditAccount.show(event);
    };
    AccountConfigurationController.prototype.formatReportName = function () {
        var _this = this;
        if (this.editedReportName) {
            var regex = /<(.*?)>/;
            var found = this.editedReportName.match(regex);
            if (found && found.length > 1) {
                this.formattedReportNameLoading = true;
                var datePattern_1 = found[1];
                this.clientService.getNowAsString(datePattern_1)
                    .then(function (response) {
                    var formattedDate = response.data;
                    _this.formattedReportName = _this.editedReportName.replace('<' + datePattern_1 + '>', formattedDate);
                    _this.formattedReportNameLoading = false;
                }, function () {
                    _this.formattedReportNameLoading = false;
                });
            }
            else {
                this.formattedReportName = this.editedReportName;
            }
        }
        else {
            this.formattedReportName = this.editedReportName;
        }
    };
    AccountConfigurationController.prototype.isFormValid = function () {
        return (this.editedClient.bdr && this.editedClient.bic && this.editedClient.emailTemplate && this.editedReportName && true);
    };
    AccountConfigurationController.prototype.clientAlreadyExists = function (testedClient) {
        var exists = false;
        this.clients.forEach(function (value) {
            var client = value;
            if (testedClient.bdr === client.bdr) {
                exists = true;
            }
        });
        return exists;
    };
    AccountConfigurationController.prototype.doSaveAccount = function () {
        var _this = this;
        this.modalEditAccountLoading = true;
        // Inject checkbox model into edited account
        this.editedClient.quoteTimes = this.adminService.toStringArray(this.editedQuoteTimes);
        this.editedClient.reportFormats = this.adminService.toStringArray(this.editedFormats);
        // Inject ReportName model into edited account
        this.editedClient.reportName = this.editedReportName;
        if (this.selectedClient === null && this.clientAlreadyExists(this.editedClient)) {
            this.closeEditModal(this._$i18next('admin:account.alreadyExists'), this._$i18next('admin:account.bdrAlreadyExists'), 'danger');
        }
        else {
            this.clientService.getClientResource().save(this.editedClient, function (requestData) {
                _this.editedClient = requestData;
                if (_this.selectedClient === null) {
                    _this.selectedClient = _this.clientService.newClient();
                    angular.copy(_this.editedClient, _this.selectedClient);
                    _this.clients.push(_this.selectedClient);
                }
                else {
                    angular.copy(_this.editedClient, _this.selectedClient);
                }
                _this.closeEditModal(_this._$i18next('common:success'), _this._$i18next('admin:account.successfullySaved'), 'success');
            }, function (error) {
                if (_this.modalEditAccountCreateMode && error.status === 500) {
                    // if BDR ID is invalid, an error message will be displayed without closing the modal
                    _this.closeEditModal(_this._$i18next('common:error'), _this._$i18next('admin:account.cannotBeSavedInvalidBdr'), 'danger', 5000);
                }
                else {
                    _this.closeEditModal(_this._$i18next('common:error'), _this._$i18next('admin:account.cannotBeSaved'), 'danger');
                }
            });
        }
    };
    AccountConfigurationController.prototype.closeEditModal = function (title, text, color, timeout) {
        this.accountValidationMsg = '';
        this.modalEditAccountLoading = false;
        // on success close modal else show msg on modal panel
        if (color === 'success') {
            this.notificationService.add(title, text, color, timeout ? timeout : 5000);
            this.modalEditAccount.hide();
        }
        else {
            this.accountValidationMsg = text;
        }
    };
    // close account panel
    AccountConfigurationController.prototype.doCancel = function () {
        this.modalEditAccount.hide();
        this.accountValidationMsg = '';
    };
    // Account deletion methods
    AccountConfigurationController.prototype.deleteAccount = function (client, event) {
        this.selectedClient = client;
        this.modalDeleteAccount.show(event);
    };
    AccountConfigurationController.prototype.doDeleteAccount = function () {
        var _this = this;
        this.modalDeleteAccountLoading = true;
        this.clientService.getClientResource().delete({ bdr: this.selectedClient.bdr }, function () {
            _this.clients.splice(_this.clients.indexOf(_this.selectedClient), 1);
            _this.closeDeleteModal('Success', _this._$i18next('admin:account.successfullyDeleted'), 'success');
        }, function (error) {
            _this.closeDeleteModal(_this._$i18next('common:error'), _this._$i18next('admin:account.cannotBeDeleted'), 'danger');
        });
    };
    AccountConfigurationController.prototype.closeDeleteModal = function (title, text, color) {
        this.notificationService.add(title, text, color, 5000);
        this.modalDeleteAccountLoading = false;
        this.modalDeleteAccount.hide();
    };
    return AccountConfigurationController;
}());
angular.module('app')
    .controller('AccountConfigurationController', AccountConfigurationController);
