///<reference path="../../../references.d.ts"/>
/**
 * Settings controller
 */
var SettingsController = (function () {
    function SettingsController(NotificationService, UserService, AdminService, $i18next) {
        var _this = this;
        this._$i18next = $i18next;
        this.notificationService = NotificationService;
        this.userResource = UserService.getUserResource();
        this.settingResource = UserService.getSettingResource();
        AdminService.getParameterResource().get(function (result) {
            var params = result;
            _this.availableFormats = params.reportFormats;
            // initialize gui with user data
            UserService.getCurrent().$promise.then(function (user) {
                _this.dailyEmailNotifications = user.dailyNotification;
                _this.editedFormats = AdminService.toBooleanMap(user.reportFormats);
                // set to false not selected formats
                _this.availableFormats.forEach(function (element) {
                    if (!_this.editedFormats[element + '']) {
                        _this.editedFormats[element + ''] = false;
                    }
                });
                _this.loaded = true;
            });
        }, function () {
            _this.notificationService.add(_this._$i18next('common:error'), _this._$i18next('admin:account.cannotRetrieveParameters'), 'danger', 5000);
            _this.loaded = false;
        });
    }
    SettingsController.prototype.setVideoUrlBasedOnLanguage = function () {
        switch (this._$i18next.options.lng) {
            case 'fr':
                this.videoUrl = 'https://video-bourse.societegenerale.com/fileadmin/user_upload/sgbourse/PayFX/PAYFX_VF.mp4';
                break;
            default:
                this.videoUrl = 'https://video-bourse.societegenerale.com/fileadmin/user_upload/sgbourse/PayFX/PAYFX.mp4';
                break;
        }
    };
    SettingsController.prototype.save = function () {
        var _this = this;
        this.loaded = false;
        var settings = this.buildUserSettings();
        var success = function () {
            _this.loaded = true;
            _this.notificationService.add(_this._$i18next('common:success'), _this._$i18next('admin:userSettings.successfullySaved'), 'success', 5000);
        };
        var error = function () {
            _this.loaded = true;
            _this.notificationService.add(_this._$i18next('common:error'), _this._$i18next('admin:userSettings.cannotSave'), 'danger', 5000);
        };
        this.settingResource.save(settings, success, error);
    };
    SettingsController.prototype.buildUserSettings = function () {
        var _this = this;
        var settings = new UserSettings();
        settings.dailyNotification = this.dailyEmailNotifications;
        settings.reportFormats = [];
        this.availableFormats.forEach(function (element) {
            if (_this.editedFormats[element + '']) {
                settings.reportFormats.push(element + '');
            }
        });
        return settings;
    };
    SettingsController.prototype.showVideoModal = function (event) {
        this.setVideoUrlBasedOnLanguage();
        this.videoModalApi.show(event);
    };
    return SettingsController;
}());
var UserSettings = (function () {
    function UserSettings() {
    }
    return UserSettings;
}());
angular.module('app')
    .config(function ($routeProvider) {
    $routeProvider
        .when('/settings', {
        controller: 'SettingsController',
        controllerAs: 'settings',
        templateUrl: "client/features/settings/settings.html",
        reloadOnSearch: false
    })
        .otherwise({
        redirectTo: '/'
    });
})
    .controller('SettingsController', SettingsController);
