///<reference path="../../references.d.ts"/>
var InstrumentRefSet = (function () {
    function InstrumentRefSet() {
    }
    return InstrumentRefSet;
}());
var InstrumentRefSetService = (function () {
    function InstrumentRefSetService($resourceBuilder, extendedHttpService) {
        this._$http = extendedHttpService;
        this.clientResource = $resourceBuilder.build('instrument-refset');
    }
    InstrumentRefSetService.prototype.getAvailableRefSets = function () {
        return this._$http.get('instrument-refset');
    };
    InstrumentRefSetService.prototype.getInstrumentRefSetResource = function () {
        return this.clientResource;
    };
    return InstrumentRefSetService;
}());
angular.module('app').service('InstrumentRefSetService', InstrumentRefSetService);
