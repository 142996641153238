///<reference path="../../../references.d.ts"/>

class PaginationController {
  private currentIndex: number; // first element to display, populate by bindToController
  private length: number; // nbElementTotal, populate by bindToController
  private hasAllPages: boolean; // Populated by bindToController
  private nextPages: Function;
  private nbElementPerPage: number;
  private pages: number[];

  // vars use to simplify template
  private firstIndexOnLastPage: number;
  private currentPage: number;
  private showBtnLastPage: boolean;
  private showSpinner: boolean;

  constructor($scope: ng.IScope) {
    this.nbElementPerPage = parseInt((<any>$scope).limit, 10);
    this.nextPages = (<any>$scope).nextPages;

    $scope.$watch(() => { return this.length; }, () => {
      if (this.length === undefined) {
        return;
      }

      let nbPages = Math.ceil(this.length / this.nbElementPerPage);
      this.pages = new Array(nbPages);
      this.currentPage = this.currentIndex / this.nbElementPerPage;
      this.firstIndexOnLastPage = (nbPages - 1) * this.nbElementPerPage;
      this.showBtnLastPage = this.hasAllPages === undefined ? true : this.hasAllPages;
      this.showSpinner = false;
    });
  }

  public changeIndex(indexNumber: number): void {
    if (this.currentIndex !== indexNumber) {
      this.currentIndex = indexNumber;
      this.currentPage = this.currentIndex / this.nbElementPerPage;
      if (this.hasAllPages === false && this.currentIndex === this.firstIndexOnLastPage) {
        this.showSpinner = true;
        this.nextPages();
      }
    }
  }

  public canShowPage(index: number): boolean {
    if (this.pages.length <= 3) {
      return true; // too small length
    }
    if (this.currentPage === 0 && index < 3) {
      return true; // 1st page + 2 pages
    }
    if (this.currentPage === this.pages.length - 1 && index + 3 >= this.pages.length) {
      return true; // last page + 2 previous pages
    }
    // middle pages
    return this.currentPage - index >= -1 && this.currentPage - index <= 1;
  }
}


angular.module('app')
  .directive('pagination', (): ng.IDirective => {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'pagination.html',
      scope: {
        limit: '@',
        nextPages: '&'
      },
      bindToController: {
        length: '=',
        hasAllPages: '=',
        currentIndex: '='
      },
      controllerAs: 'pgCtl',
      controller: PaginationController
    };
  });