///<reference path="../../references.d.ts"/>
var Instrument = (function () {
    function Instrument() {
        this.group = 'DEFAULT';
        this.precision = 4;
    }
    return Instrument;
}());
var InstrumentService = (function () {
    function InstrumentService($resourceBuilder) {
        this.clientResource = $resourceBuilder.build('instrument/:name');
    }
    InstrumentService.prototype.getInstrumentResource = function () {
        return this.clientResource;
    };
    InstrumentService.prototype.newInstrument = function () {
        return new Instrument();
    };
    return InstrumentService;
}());
angular.module('app').service('InstrumentService', InstrumentService);
