///<reference path="../../references.d.ts"/>
/**
 * Display a boolean value using the text values passed as parameters
 */
angular.module('app')
    .filter('booleanDisplay', function () {
    return function (input, yes, no) {
        if (input) {
            return (yes) ? yes : 'yes';
        }
        else {
            return (no) ? no : 'no';
        }
    };
});
