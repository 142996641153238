///<reference path="../../references.d.ts"/>
/**
 * Show number as percentage
 */
angular.module('app')
    .filter('percentage', ['$filter', function ($filter) {
        return function (input, decimals) {
            if (angular.isUndefined(input) || input == null) {
                return '';
            }
            return $filter('number')(input * 100, decimals) + '%';
        };
    }]);
