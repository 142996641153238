///<reference path="../../../references.d.ts"/>

/**
 * Settings controller 
 */

class SettingsController {
  private _$i18next: any;
  private notificationService: INotificationService;
  private userResource: IUserResource;
  private settingResource: any;

  private videoModalApi: IEzModalApi;

  private loaded: boolean;

  private dailyEmailNotifications: boolean;

  private videoUrl: string;

  private availableFormats: String[];
  private editedFormats: { [key: string]: boolean };

  constructor(NotificationService: INotificationService, UserService: IUserService, AdminService: IAdminService, $i18next: any) {
    this._$i18next = $i18next;
    this.notificationService = NotificationService;
    this.userResource = UserService.getUserResource();
    this.settingResource = UserService.getSettingResource();
    AdminService.getParameterResource().get((result: ng.resource.IResource<IAvailableParameters>) => {
      let params: IAvailableParameters = result as any;
      this.availableFormats = params.reportFormats;
      // initialize gui with user data
      UserService.getCurrent().$promise.then((user: IUser) => {
        this.dailyEmailNotifications = user.dailyNotification;

        this.editedFormats = AdminService.toBooleanMap(user.reportFormats);
        // set to false not selected formats
        this.availableFormats.forEach(element => {
          if (!this.editedFormats[element + '']) {
            this.editedFormats[element + ''] = false;
          }
        });
        this.loaded = true;
      });
    }, () => {
      this.notificationService.add(this._$i18next('common:error'), this._$i18next('admin:account.cannotRetrieveParameters'), 'danger', 5000);
      this.loaded = false;
    });
  }

  setVideoUrlBasedOnLanguage(): void {
    switch (this._$i18next.options.lng) {
      case 'fr':
        this.videoUrl = 'https://video-bourse.societegenerale.com/fileadmin/user_upload/sgbourse/PayFX/PAYFX_VF.mp4';
        break;
      default:
        this.videoUrl = 'https://video-bourse.societegenerale.com/fileadmin/user_upload/sgbourse/PayFX/PAYFX.mp4';
        break;
    }
  }

  save(): void {
    this.loaded = false;
    let settings = this.buildUserSettings();

    let success = () => {
      this.loaded = true;
      this.notificationService.add(this._$i18next('common:success'), this._$i18next('admin:userSettings.successfullySaved'), 'success', 5000);
    };
    let error = () => {
      this.loaded = true;
      this.notificationService.add(this._$i18next('common:error'), this._$i18next('admin:userSettings.cannotSave'), 'danger', 5000);
    };

    this.settingResource.save(settings, success, error);
  }

  buildUserSettings(): UserSettings {
    let settings = new UserSettings();
    settings.dailyNotification = this.dailyEmailNotifications;
    settings.reportFormats = [];
    this.availableFormats.forEach(element => {
      if (this.editedFormats[element + '']) {
        settings.reportFormats.push(element + '');
      }
    });
    return settings;
  }

  public showVideoModal(event: Event) {
    this.setVideoUrlBasedOnLanguage();
    this.videoModalApi.show(event);
  }
}

class UserSettings implements IUserSettings {
  dailyNotification: boolean;
  reportFormats: string[];
}

angular.module('app')
  .config(function ($routeProvider: ng.route.IRouteProvider): void {
    $routeProvider
      .when('/settings', {
        controller: 'SettingsController',
        controllerAs: 'settings',
        templateUrl: './settings.html',
        reloadOnSearch: false
      })
      .otherwise({
        redirectTo: '/'
      });
  })
  .controller('SettingsController', SettingsController)
  ;
