///<reference path="../../references.d.ts"/>

class EhancedMargedQuote implements IEnhancedQuote {
  public instrument: string;
  public value: number;
  public leftCurrency: string;
  public rightCurrency: string;
  public precision: number;
  public delta: number;
  public alert: boolean;

  constructor(quote: IQuote) {
    this.instrument = quote.instrument;
    this.value = quote.value;
    this.leftCurrency = quote.instrument.substring(0, 3);
    this.rightCurrency = quote.instrument.substring(3, 7);
    this.precision = quote.precision;
    this.alert = quote.alert;
    this.delta = quote.delta;
  }
}


class SnapshotService implements ISnapshotService {
  private _$http: ng.IHttpService;
  private supportService: ISupportService;

  constructor(extendedHttpService: ng.IHttpService, SupportService: ISupportService) {
    this._$http = extendedHttpService;
    this.supportService = SupportService;
  }

  public getSnapshotsList(clientId?: number): ng.IHttpPromise<ILightSnapshot[]> {
    const route = clientId ? `snapshot?bdr=${clientId}` : 'snapshot';
    let url = this.supportService.addSelectedUserToUrlIfAny(route);
    return this._$http.get(url);
  }

  public getSnapshot(id: string, clientId?: number): ng.IHttpPromise<ISnapshotWrapper[]> {
    const route = clientId ? `snapshot/${id}?bdr=${clientId}` : `snapshot/${id}`;
    let url = this.supportService.addSelectedUserToUrlIfAny(route);
    return this._$http.get(url);
  }

  public createRaws(): ng.IHttpPromise<ISnapshotWrapper[]> {
    return this._$http.post('snapshot/raw', '{"newGrid":true}'); // <- post trash to avoid 'firewall empty post' issue
  }

  public createSnapshotForClients(rawSnapshotId: string, bdrs: number[]): ng.IHttpPromise<string> {
    return this._$http.post(`snapshot/raw/${rawSnapshotId}`, bdrs);
  }

  public regenerateGrid(): ng.IHttpPromise<any> {
    return this._$http.post(`snapshot/regenerate`, null);
  }

  public enhanceQuote(quote: IQuote): IEnhancedQuote {
    return new EhancedMargedQuote(quote);
  }

}

angular.module('app').service('SnapshotService', SnapshotService);
