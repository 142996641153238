/// <reference path="../../references.d.ts"/>

class AdminService implements IAdminService {


  private parameterResource: ng.resource.IResourceClass<ng.resource.IResource<IAvailableParameters>>;
  private defaultParameterResource: ng.resource.IResourceClass<ng.resource.IResource<IDefaultParameters>>;

  constructor($resourceBuilder) {
    this.parameterResource = $resourceBuilder.build('parameter');
    this.defaultParameterResource = $resourceBuilder.build('parameter/default');
  }

  public getParameterResource(): ng.resource.IResourceClass<ng.resource.IResource<IAvailableParameters>> {
    return this.parameterResource;
  }

  public getDefaultParameterResource(): ng.resource.IResourceClass<ng.resource.IResource<IDefaultParameters>> {
    return this.defaultParameterResource;
  }

  public toBooleanMap(arr: string[]): { [key: string]: boolean } {
    let returnMap: { [key: string]: boolean } = {};
    if (arr) {
      arr.forEach((str: string) => {
        returnMap[str] = true;
      });
    }
    return returnMap;
  }

  public toStringArray(map: { [key: string]: boolean }): string[] {
    let returnArray: string[] = [];
    if (map) {
      Object.keys(map).forEach((key: string) => {
        if (map[key]) {
          returnArray.push(key);
        }
      });
    }
    return returnArray;
  }

}

angular.module('app').service('AdminService', AdminService);
