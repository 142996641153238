///<reference path="../../../references.d.ts"/>
var SnapshotPickerController = (function () {
    function SnapshotPickerController($location, $scope) {
        var _this = this;
        $scope.$watch(function () { return _this.ngData; }, function () {
            _this.snapshotsByStrDate = {};
            if (!_this.ngData) {
                return;
            }
            // A snapshot.snapshotDateTime is a date in ISO String so they are sortable without type convertion. Init 2 string to find min/max date.
            var minStrDateTime = '3999-31-12T23:59:59.99Z';
            var maxStrDateTime = '';
            _this.ngData.forEach(function (snapshot) {
                // Find oldest available date
                if (snapshot.snapshotDateTime < minStrDateTime) {
                    minStrDateTime = snapshot.snapshotDateTime;
                }
                // Find newest available date
                if (snapshot.snapshotDateTime > maxStrDateTime) {
                    maxStrDateTime = snapshot.snapshotDateTime;
                    // last snapshot will be the selected in date-picker
                    _this.selectedSnapshot = snapshot;
                }
                // Build snapshots Model
                var strDate = snapshot.snapshotDateTime.substr(0, 10);
                if (!_this.snapshotsByStrDate[strDate]) {
                    _this.snapshotsByStrDate[strDate] = [];
                }
                _this.snapshotsByStrDate[strDate].push(snapshot);
            });
            // Init date-picker dateTime range
            _this.startDateTime = new Date(minStrDateTime);
            _this.endDateTime = new Date(maxStrDateTime);
            // Init selected date in date-picker
            _this.selectedDateMidnight = new Date(maxStrDateTime.substr(0, 10));
        });
        $scope.$watch(function () { return _this.selectedDateMidnight; }, function () {
            if (_this.selectedDateMidnight) {
                _this.availableSnapshots = _this.snapshotsByStrDate[_this.selectedDateMidnight.toISOString().substr(0, 10)];
            }
        });
    }
    SnapshotPickerController.prototype.selectSnapshot = function (snapshot) {
        this.ngModel = snapshot.id;
        this.selectedSnapshot = snapshot;
    };
    return SnapshotPickerController;
}());
angular.module('app')
    .directive('snapshotPicker', function () {
    return {
        restrict: 'EA',
        replace: true,
        templateUrl: "client/directives/snapshotPicker/snapshotPicker.html",
        scope: {},
        bindToController: {
            ngData: '=',
            ngModel: '='
        },
        controllerAs: 'snapCtl',
        controller: SnapshotPickerController
    };
});
