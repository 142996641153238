///<reference path="../../references.d.ts"/>
/**
 * Display a boolean value using the text values passed as parameters
 */
angular.module('app')
  .filter('booleanDisplay', function (): (input: boolean, yes?: string, no?: string) => string {
    return function (input: boolean, yes?: string, no?: string): string {
      if (input) { // yes
        return (yes) ? yes : 'yes';
      }
      else { // no
        return (no) ? no : 'no';
      }
    };
  })
  ;
