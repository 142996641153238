///<reference path="../../references.d.ts"/>
/**
 * Displays a 'EURGBP' technical currency name as 'EUR/GBP'
 */
angular.module('app')
  .filter('instrumentName', function (): (input: string) => string {
    return function (input: string): string {
      if (angular.isUndefined(input) || input == null) {
        return '-/-';
      }

      let result: string;
      let leftCurrency = input.substring(0, 3);
      let rightCurrency = input.substring(3, 7);
      return leftCurrency + '/' + rightCurrency;
    };
  }).filter('instrumentNameList', function (): (input: string[]) => string {
    return function (input: string[]): string {
      if (angular.isUndefined(input) || input == null) {
        return '';
      }
      let result: string;
      input.forEach((value: string) => {
        let leftCurrency = value.substring(0, 3);
        let rightCurrency = value.substring(3, 7);
        result = result ? result + ', ' + leftCurrency + '/' + rightCurrency : leftCurrency + '/' + rightCurrency;
      });
      return result;
    };
  })
  ;
