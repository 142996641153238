///<reference path="../../references.d.ts"/>
var EhancedMargedQuote = (function () {
    function EhancedMargedQuote(quote) {
        this.instrument = quote.instrument;
        this.value = quote.value;
        this.leftCurrency = quote.instrument.substring(0, 3);
        this.rightCurrency = quote.instrument.substring(3, 7);
        this.precision = quote.precision;
        this.alert = quote.alert;
        this.delta = quote.delta;
    }
    return EhancedMargedQuote;
}());
var SnapshotService = (function () {
    function SnapshotService(extendedHttpService, SupportService) {
        this._$http = extendedHttpService;
        this.supportService = SupportService;
    }
    SnapshotService.prototype.getSnapshotsList = function (clientId) {
        var route = clientId ? "snapshot?bdr=" + clientId : 'snapshot';
        var url = this.supportService.addSelectedUserToUrlIfAny(route);
        return this._$http.get(url);
    };
    SnapshotService.prototype.getSnapshot = function (id, clientId) {
        var route = clientId ? "snapshot/" + id + "?bdr=" + clientId : "snapshot/" + id;
        var url = this.supportService.addSelectedUserToUrlIfAny(route);
        return this._$http.get(url);
    };
    SnapshotService.prototype.createRaws = function () {
        return this._$http.post('snapshot/raw', '{"newGrid":true}'); // <- post trash to avoid 'firewall empty post' issue
    };
    SnapshotService.prototype.createSnapshotForClients = function (rawSnapshotId, bdrs) {
        return this._$http.post("snapshot/raw/" + rawSnapshotId, bdrs);
    };
    SnapshotService.prototype.regenerateGrid = function () {
        return this._$http.post("snapshot/regenerate", null);
    };
    SnapshotService.prototype.enhanceQuote = function (quote) {
        return new EhancedMargedQuote(quote);
    };
    return SnapshotService;
}());
angular.module('app').service('SnapshotService', SnapshotService);
