///<reference path="../../../../references.d.ts"/>

/**
 * Account Configuration tab controller
 */

class AccountConfigurationController {
  private _$scope: ng.IScope;
  private _$i18next: Function;
  private notificationService: INotificationService;
  private clientService: IClientService;
  private instrumentService: IInstrumentService;
  private adminService: IAdminService;
  private loaded: boolean;

  // Reference model loaded from server
  private clients: ng.resource.IResourceArray<ng.resource.IResource<IClient>>;
  private defaultParameters: IDefaultParameters;

  // Form Model
  private selectedClient: IClient;
  private selectedInstruments: { [key: string]: string[] };
  private editedClient: IClient;
  private editedQuoteTimes: { [key: string]: boolean };
  private editedFormats: { [key: string]: boolean };
  private editedReportName: string;

  private formattedReportName: string;
  private formattedReportNameLoading: boolean;

  // Available parameters model
  private availableInstruments: ng.resource.IResourceArray<ng.resource.IResource<IInstrument>>;
  private availableEmailTemplates: string[];
  private availableQuoteTimes: String[];
  private availableFormats: String[];

  // Modals Model
  private modalShowInstruments: IEzModalApi;
  private modalEditAccount: IEzModalApi;
  private modalEditAccountShowCcy: boolean;
  private modalEditAccountCreateMode: boolean;
  private modalEditAccountLoading: boolean;
  private modalDeleteAccount: IEzModalApi;
  private modalDeleteAccountLoading: boolean;
  private accountValidationMsg: string = '';

  public appFileSaver;

  constructor(ClientService: IClientService, InstrumentService: IInstrumentService, AdminService: IAdminService, NotificationService: INotificationService, $scope: ng.IScope, $i18next: Function, $appFileSaver) {
    this._$scope = $scope;
    this._$i18next = $i18next;
    this.clientService = ClientService;
    this.instrumentService = InstrumentService;
    this.adminService = AdminService;
    this.notificationService = NotificationService;
    this.appFileSaver = $appFileSaver;
    this.clients = this.clientService.getClientResource().query({ 'full': true }, () => {
      this.adminService.getParameterResource().get((result: ng.resource.IResource<IAvailableParameters>) => {
        let params: IAvailableParameters = result as any;
        this.availableEmailTemplates = params.emailTemplates;
        this.availableFormats = params.reportFormats;
        this.availableQuoteTimes = params.quoteTimes;
        this.loaded = true;
      }, () => {
        this.notificationService.add(this._$i18next('common:error'), this._$i18next('admin:account.cannotRetrieveParameters'), 'danger', 5000);
        this.loaded = true;
      });
    }, (error: ng.IHttpPromiseCallbackArg<IClient>) => {
      this.notificationService.add(this._$i18next('common:error'), this._$i18next('admin:account.cannotRetrieveAccountList'), 'danger', 5000);
      this.loaded = true;
    });
    // TODO Refactor to load instruments once for the whole admin page
    this.availableInstruments = this.instrumentService.getInstrumentResource().query(() => { },
      (error: ng.IHttpPromiseCallbackArg<IClient>) => {
        this.notificationService.add(this._$i18next('common:error'), this._$i18next('admin:account.cannotRetrieveInstrumentList'), 'danger', 5000);
      });

    this.adminService.getDefaultParameterResource().get(
      (result: ng.resource.IResource<IDefaultParameters>) => {
        this.defaultParameters = result as any;
      },
      () => {
        this.notificationService.add(this._$i18next('common:error'), this._$i18next('admin:account.cannotRetrieveDefaultParamList'), 'danger', 5000);
        this.loaded = true;
      });
  }

  public showInstruments(client: IClient, event: Event) {
    this.selectedClient = client;
    this.selectedInstruments = this.buildInstrumentsMap(this.selectedClient.instruments);
    this.modalShowInstruments.show(event);
  }

  private buildInstrumentsMap(instruments: string[]): { [key: string]: string[] } {
    let instrumentMap: { [key: string]: string[] } = {};
    this.selectedClient.instruments.forEach((instrument: string) => {
      let pivot: string = instrument.substring(0, 3);
      // Build margedQuotes Map
      if (!instrumentMap[pivot]) {
        instrumentMap[pivot] = [];
      }
      instrumentMap[pivot].push(instrument);
    });
    return instrumentMap;
  }

  // Account edition methods

  public createAccount(event: Event) {
    this.selectedClient = null;
    this.editedClient = this.clientService.newClient();
    this.editedQuoteTimes = {};
    this.editedFormats = {};
    this.editedFormats = this.adminService.toBooleanMap(this.defaultParameters.reportFormats);
    this.editedQuoteTimes = this.adminService.toBooleanMap(this.defaultParameters.quoteTimes);
    this.editedReportName = 'SG Daily currency prices for <yyyyMMdd>';
    this.formatReportName();
    this.modalEditAccountCreateMode = true;
    this.modalEditAccountShowCcy = false;
    this.modalEditAccount.show(event);
  }

  public editAccount(client: IClient, event: Event) {
    delete (client as any).$rowConfig;
    this.selectedClient = client;
    this.editedClient = angular.copy(this.selectedClient);
    // Initialize checkbox model for modals
    this.editedQuoteTimes = this.adminService.toBooleanMap(this.editedClient.quoteTimes);
    this.editedFormats = this.adminService.toBooleanMap(this.editedClient.reportFormats);
    this.editedReportName = this.editedClient.reportName;
    this.formatReportName();
    this.modalEditAccountCreateMode = false;
    this.modalEditAccountShowCcy = false;
    this.modalEditAccount.show(event);
  }

  private formatReportName() {
    if (this.editedReportName) {
      const regex = /<(.*?)>/;
      const found = this.editedReportName.match(regex);
      if (found && found.length > 1) {
        this.formattedReportNameLoading = true;
        const datePattern = found[1];
        this.clientService.getNowAsString(datePattern)
        .then((response) => {
          const formattedDate = response.data;
          this.formattedReportName = this.editedReportName.replace('<' + datePattern + '>', formattedDate);
          this.formattedReportNameLoading = false;
        }, () => {
          this.formattedReportNameLoading = false;
        }
        );
      } else {
        this.formattedReportName = this.editedReportName;
      }
    } else {
      this.formattedReportName = this.editedReportName;
    }
  }

  public isFormValid(): boolean {
    return (this.editedClient.bdr && this.editedClient.bic && this.editedClient.emailTemplate && this.editedReportName && true);
  }

  private clientAlreadyExists(testedClient: IClient) {
    let exists: boolean = false;
    this.clients.forEach((value: ng.resource.IResource<IClient>) => {
      let client: IClient = value as any;
      if (testedClient.bdr === client.bdr) {
        exists = true;
      }
    });
    return exists;
  }

  public doSaveAccount() {
    this.modalEditAccountLoading = true;
    // Inject checkbox model into edited account
    this.editedClient.quoteTimes = this.adminService.toStringArray(this.editedQuoteTimes);
    this.editedClient.reportFormats = this.adminService.toStringArray(this.editedFormats);
    // Inject ReportName model into edited account
    this.editedClient.reportName = this.editedReportName;
    if (this.selectedClient === null && this.clientAlreadyExists(this.editedClient)) {
      this.closeEditModal(this._$i18next('admin:account.alreadyExists'), this._$i18next('admin:account.bdrAlreadyExists'), 'danger');
    } else {
      this.clientService.getClientResource().save(this.editedClient,
        (requestData: ng.resource.IResource<IClient>) => {
          this.editedClient = requestData as any;
          if (this.selectedClient === null) { // create client
            this.selectedClient = this.clientService.newClient();
            angular.copy(this.editedClient, this.selectedClient);
            this.clients.push(this.selectedClient as any);
          } else { // edit client
            angular.copy(this.editedClient, this.selectedClient);
          }
          this.closeEditModal(this._$i18next('common:success'), this._$i18next('admin:account.successfullySaved'), 'success');
        }, (error: ng.IHttpPromiseCallbackArg<IClient>) => {
          if (this.modalEditAccountCreateMode && error.status === 500) {
            // if BDR ID is invalid, an error message will be displayed without closing the modal
            this.closeEditModal(this._$i18next('common:error'), this._$i18next('admin:account.cannotBeSavedInvalidBdr'), 'danger', 5000);
          } else {
            this.closeEditModal(this._$i18next('common:error'), this._$i18next('admin:account.cannotBeSaved'), 'danger');
          }
        });
    }
  }

  private closeEditModal(title: string, text: string, color: NotifType, timeout?: number) {
    this.accountValidationMsg = '';
    this.modalEditAccountLoading = false;
    // on success close modal else show msg on modal panel
    if (color === 'success') {
      this.notificationService.add(title, text, color, timeout ? timeout : 5000);
      this.modalEditAccount.hide();
    }
    else {
      this.accountValidationMsg = text;
    }
  }

    // close account panel
  private doCancel() {
      this.modalEditAccount.hide();
    this.accountValidationMsg = '';
  }

  // Account deletion methods

  public deleteAccount(client: IClient, event: Event) {
    this.selectedClient = client;
    this.modalDeleteAccount.show(event);
  }

  public doDeleteAccount() {
    this.modalDeleteAccountLoading = true;
    this.clientService.getClientResource().delete({ bdr: this.selectedClient.bdr },
      () => {
        this.clients.splice(this.clients.indexOf(this.selectedClient as any), 1);
        this.closeDeleteModal('Success', this._$i18next('admin:account.successfullyDeleted'), 'success');
      }, (error: ng.IHttpPromiseCallbackArg<IClient>) => {
        this.closeDeleteModal(this._$i18next('common:error'), this._$i18next('admin:account.cannotBeDeleted'), 'danger');
      });
  }

  private closeDeleteModal(title: string, text: string, color: NotifType) {
    this.notificationService.add(title, text, color, 5000);
    this.modalDeleteAccountLoading = false;
    this.modalDeleteAccount.hide();
  }
}

angular.module('app')
  .controller('AccountConfigurationController', AccountConfigurationController);
