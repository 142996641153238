///<reference path="../../references.d.ts"/>
/**
 * Displays a 'EURGBP' technical currency name as 'EUR/GBP'
 */
angular.module('app')
    .filter('instrumentName', function () {
    return function (input) {
        if (angular.isUndefined(input) || input == null) {
            return '-/-';
        }
        var result;
        var leftCurrency = input.substring(0, 3);
        var rightCurrency = input.substring(3, 7);
        return leftCurrency + '/' + rightCurrency;
    };
}).filter('instrumentNameList', function () {
    return function (input) {
        if (angular.isUndefined(input) || input == null) {
            return '';
        }
        var result;
        input.forEach(function (value) {
            var leftCurrency = value.substring(0, 3);
            var rightCurrency = value.substring(3, 7);
            result = result ? result + ', ' + leftCurrency + '/' + rightCurrency : leftCurrency + '/' + rightCurrency;
        });
        return result;
    };
});
