///<reference path="../../../../references.d.ts"/>

/**
 * Contact Configuration tab controller
 */

class ContactConfigurationController {
  private _$scope: ng.IScope;
  private _$i18next: Function;
  private notificationService: INotificationService;
  private userService: IUserService;
  private clientService: IClientService;
  private adminService: IAdminService;
  private loaded: boolean;

  // Reference model loaded from server
  private users: ng.resource.IResourceArray<ng.resource.IResource<IUser>>;

  // Form Model
  private selectedUser: IUser;
  private editedUser: IUser;
  private controlThatUserBelongsToClient: boolean;
  private editedFormats: { [key: string]: boolean };

  // Available parameters model
  private availableClients: ng.resource.IResourceArray<ng.resource.IResource<IClient>>;
  private availableFormats: String[];

  // Modals Model
  private modalShowInstruments: IEzModalApi;

  private modalEditContact: IEzModalApi;
  private modalEditContactCreateMode: boolean;
  private modalEditContactLoading: boolean;

  private modalDeleteContact: IEzModalApi;
  private modalDeleteContactLoading: boolean;
  private contactValidationMsg: string = '';
  private clientIcIds: IClientId[] = [];

  public appFileSaver;


  constructor(UserService: IUserService, ClientService: IClientService, AdminService: IAdminService, NotificationService: INotificationService, $scope: ng.IScope, $i18next: Function, $appFileSaver) {
    this._$scope = $scope;
    this._$i18next = $i18next;
    this.userService = UserService;
    this.adminService = AdminService;
    this.clientService = ClientService;
    this.notificationService = NotificationService;
    this.appFileSaver = $appFileSaver;

    this.users = this.userService.getUserResource().query(() => {
      this.adminService.getParameterResource().get((result: ng.resource.IResource<IAvailableParameters>) => {
        let params: IAvailableParameters = result as any;
        this.availableFormats = params.reportFormats;
        this.loaded = true;
      }, () => {
        this.notificationService.add(this._$i18next('common:error'), this._$i18next('admin:account.cannotRetrieveParameters'), 'danger', 5000);
        this.loaded = true;
      });
    }, (error: ng.IHttpPromiseCallbackArg<IClient>) => {
      this.notificationService.add(this._$i18next('common:error'), this._$i18next('admin:contact.cannotRetrieveContactList'), 'danger', 5000);
      this.loaded = true;
    });
    this.availableClients = this.clientService.getClientResource().query({'full': true}, () => {
      },
      (error: ng.IHttpPromiseCallbackArg<IClient>) => {
        this.notificationService.add(this._$i18next('common:error'), this._$i18next('admin:account.cannotRetrieveAccountList'), 'danger', 5000);
      });
  }

  public getClientIcId(client: IClientId) {
    this.clientService.getClientIcId(client.bdr).then((response) => {
      let found: boolean = false;
      const icIdFromService: string = response.data;
      for (let i = 0; i < this.clientIcIds.length; i++) {
        if (!found) {
          found = this.clientIcIds[i].icId === icIdFromService;
        }
      }
      if (!found) {
        const newClientId: IClientId = this.clientService.newClientId();
        newClientId.bdr = client.bdr;
        newClientId.icId = icIdFromService;
        newClientId.cambistName = client.cambistName;
        this.clientIcIds.push(newClientId);
      }
    }, (error) => {
      this.notificationService.add(this._$i18next('common:error'), this._$i18next('admin:account.cannotRetrieveParameters'), 'danger', 5000);

    });
  }

  // Contact edition methods
  private createContact(event: Event) {
    this.selectedUser = null;
    this.editedUser = this.userService.newUser();
    this.controlThatUserBelongsToClient = true;
    this.editedFormats = {};
    this.modalEditContactCreateMode = true;
    this.modalEditContact.show(event);
  }

  private computeDefaultReportFormats() {
    this.editedFormats = this.adminService.toBooleanMap(this.getClientsReportFormats(this.editedUser));
  }

  public addClient(client: IClientId) {
    this.getClientIcId(client);
    this.computeDefaultReportFormats();
  }

  public removeClient(client: IClientId) {
    for (let i = 0; i < this.clientIcIds.length; i++) {
     if (this.clientIcIds[i].bdr === client.bdr) {
      this.clientIcIds.splice(i, 1);
       break;
     }
    }
    this.computeDefaultReportFormats();
  }


  private getClientsReportFormats(user: IUser): string[] {
    const reports = [];
    if (user.clients) {
      for (let i = 0; i < user.clients.length; i++) {
        const client = user.clients[i];
        for (let j = 0; j < client.reportFormats.length; j++) {
          const format = client.reportFormats[i];
          if (format && reports.indexOf(format) < 0) {
            reports.push(format);
          }
        }
      }
    }
    return reports;
  }

  // Called when client email is changed in modal
  public formatEditedEmail() {
    if (this.editedUser && this.editedUser.email) {
      this.editedUser.email = this.editedUser.email.toLowerCase();
    }
  }

  private editContact(user: IUser, event: Event) {
    delete (user as any).$rowConfig;
    this.selectedUser = user;
    this.editedUser = angular.copy(this.selectedUser);
    // Initialize checkbox model for modals
    this.editedFormats = this.adminService.toBooleanMap(this.editedUser.reportFormats);

    this.modalEditContactCreateMode = false;
    this.modalEditContact.show(event);
  }

  public isFormValid(): boolean {
    return (this.editedUser.email && this.editedUser.clients && this.editedUser.clients.length > 0 && true);
  }

  private userAlreadyExists(testedUser: IUser): boolean {
    let exists: boolean = false;
    this.users.forEach((value: ng.resource.IResource<IUser>) => {
      let user: IUser = value as any;
      if (testedUser.email === user.email) {
        exists = true;
      }
    });
    return exists;
  }

  private doSaveContact() {
    this.modalEditContactLoading = true;
    // Inject checkbox model into edited objects
    this.editedUser.reportFormats = this.adminService.toStringArray(this.editedFormats);
    let userForm: IUserForm = this.userService.userFormFromUser(this.editedUser);

    if (this.selectedUser === null && this.userAlreadyExists(this.editedUser)) {
      this.closeEditModal(this._$i18next('admin:contact.alreadyExistsTitle'), this._$i18next('admin:contact.alreadyExistsMessage'), 'danger');
    } else {
      this.userService.getUserResource().save({controlThatUserBelongsToClient: this.controlThatUserBelongsToClient}, userForm,
        (requestData: ng.resource.IResource<IUser>) => {
          this.editedUser = requestData as any;
          if (this.selectedUser === null) { // create user
            this.selectedUser = this.userService.newUser();
            angular.copy(this.editedUser, this.selectedUser);
            this.users.push(this.selectedUser as any);
          } else { // edit user
            angular.copy(this.editedUser, this.selectedUser);
          }
          this.closeEditModal(this._$i18next('common:success'), this._$i18next('admin:contact.successfullySaved'), 'success');
        }, (error: ng.IHttpPromiseCallbackArg<IClient>) => {
          if (this.modalEditContactCreateMode && error.status > 400 && error.status < 500) {
            this.closeEditModal(this._$i18next('common:error'), this._$i18next('admin:contact.cannotBeSavedInvalidMail', {
              'email': this.editedUser.email,
              'bdr': this.getClientsAsString(this.editedUser)
            }), 'danger', 10000);
          } else {
            this.closeEditModal(this._$i18next('common:error'), this._$i18next('admin:contact.cannotBeSaved'), 'danger');
          }
        });
    }
  }

  private closeEditModal(title: string, text: string, color: NotifType, timeout?: number) {
    this.contactValidationMsg = '';
    this.modalEditContactLoading = false;
    // on success close modal else show msg on modal panel
    if (color === 'success') {
      this.notificationService.add(title, text, color, timeout ? timeout : 5000);
      this.modalEditContact.hide();
    }
    else {
      this.contactValidationMsg = text;
    }

  }

  // close contact panel
  private doCancel() {
    this.modalEditContact.hide();
    this.contactValidationMsg = '';
  }

  public getClientsAsString(user: IUser): string {
    const clientsAsString = user.clients.map(client => `${client.cambistName} (${client.bdr})`).join(', ');
    return clientsAsString;
  }

  // Contact deletion methods
  public deleteContact(user: IUser, event: Event) {
    this.selectedUser = user;
    this.modalDeleteContact.show(event);
  }

  private doDeleteContact() {
    this.modalDeleteContactLoading = true;
    this.userService.getUserResource().delete({email: this.selectedUser.email},
      () => {
        this.users.splice(this.users.indexOf(this.selectedUser as any), 1);
        this.closeDeleteModal(this._$i18next('common:success'), this._$i18next('admin:contact.successfullyDeleted'), 'success');
      }, (error: ng.IHttpPromiseCallbackArg<IClient>) => {
        this.closeDeleteModal(this._$i18next('common:error'), this._$i18next('admin:contact.cannotBeDeleted'), 'danger');
      });
  }

  private closeDeleteModal(title: string, text: string, color: NotifType) {
    this.notificationService.add(title, text, color, 5000);
    this.modalDeleteContactLoading = false;
    this.modalDeleteContact.hide();
  }
}

angular.module('app')
  .controller('ContactConfigurationController', ContactConfigurationController);
