///<reference path="../../../references.d.ts"/>

class NavigateAsController {
  private supportService: ISupportService;

  private _$location: ng.ILocationService;
  private navigateAsEmail: string;

  constructor($location: ng.ILocationService, SupportService: ISupportService) {
    this._$location = $location;
    this.supportService = SupportService;
  }

  public selectUser() {
    this.supportService.setSelectedUser(this.navigateAsEmail);
    // go to home page
    this._$location.path('/');
    window.location.reload();
  }
}

angular.module('app')
  .directive('navigateContacts', (): ng.IDirective => {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'navigateContacts.html',
      scope: true,
      controllerAs: 'navCtrl',
      controller: NavigateAsController
    };
  });
