///<reference path="../../references.d.ts"/>
/**
 * directive used to automatically formats a number in the <input>
 */
angular.module('app')
    .directive('format', function ($filter) {
    return {
        require: '?ngModel',
        link: function (scope, elem, attrs, ctrl) {
            if (!ctrl) {
                return;
            }
            ctrl.$formatters.unshift(function () {
                return $filter(attrs.format)(ctrl.$modelValue);
            });
            ctrl.$parsers.unshift(function (viewValue) {
                if (viewValue) {
                    var plainNumber = viewValue.replace(/[^0-9.]/g, '');
                    var myDecimals = plainNumber.match(/\.\d{0,2}/);
                    if (myDecimals) {
                        plainNumber = plainNumber.slice(0, plainNumber.indexOf('.'));
                    }
                    var formatted = $filter(attrs.format)(plainNumber);
                    if (myDecimals) {
                        formatted += myDecimals[0];
                        plainNumber += myDecimals[0];
                    }
                    elem.val(formatted);
                    return plainNumber;
                }
                else {
                    return '';
                }
            });
        }
    };
});
