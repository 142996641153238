///<reference path="../../../../references.d.ts"/>

const CURRENCY_PAIR_REGEX: RegExp = /^[A-Z]{3}[/]{0,1}[A-Z]{3}$/;

/**
 * Default Parameters tab controller
 */

class DefaultParametersController {
  private _$scope: ng.IScope;
  private _$i18next: Function;
  private notificationService: INotificationService;
  private instrumentService: IInstrumentService;
  private instrumentRefSetService: IInstrumentRefSetService;
  private clientService: IClientService;
  private adminService: IAdminService;
  private loaded: boolean;

  // Model provided by services 
  private defaultParameters: IDefaultParameters;
  private availableQuoteFormats: string[];
  private editedQuoteFormat: { [key: string]: boolean };
  private availablePricingTime: string[];
  private editedPricingTime: { [key: string]: boolean };
  private instruments: ng.resource.IResourceArray<ng.resource.IResource<IInstrument>>;

  // Settings Edition Model
  private availableRefSets: IInstrumentRefSet[];
  private selectedInstrument: IInstrument;
  private selectedInstrumentAccounts: IClient[];
  private editedInstrument: IInstrument;
  private defaultParametersSaving: boolean;

  // Modals
  private modalEditInstrument: IEzModalApi;
  private modalEditInstrumentLoading: boolean;
  private modalDeleteInstrument: IEzModalApi;
  private modalDeleteInstrumentLoading: boolean;

  public appFileSaver;


  constructor(InstrumentService: IInstrumentService, InstrumentRefSetService: IInstrumentRefSetService, ClientService: IClientService, AdminService: IAdminService, NotificationService: INotificationService, $scope: ng.IScope, $i18next: Function, $appFileSaver) {
    this._$scope = $scope;
    this._$i18next = $i18next;
    this.notificationService = NotificationService;
    this.instrumentService = InstrumentService;
    this.instrumentRefSetService = InstrumentRefSetService;
    this.clientService = ClientService;
    this.adminService = AdminService;
    this.appFileSaver = $appFileSaver;

    this.instruments = this.instrumentService.getInstrumentResource().query(() => {
      this.adminService.getParameterResource().get((result: ng.resource.IResource<IAvailableParameters>) => {
        let params: IAvailableParameters = result as any;
        this.availableQuoteFormats = params.reportFormats;
        this.availablePricingTime = params.quoteTimes;
        this.adminService.getDefaultParameterResource().get(
          (result: ng.resource.IResource<IDefaultParameters>) => {
            this.defaultParameters = result as any;
            this.editedPricingTime = this.adminService.toBooleanMap(this.defaultParameters.quoteTimes);
            this.editedQuoteFormat = this.adminService.toBooleanMap(this.defaultParameters.reportFormats);
            this.loaded = true;
          }, () => {
            this.notificationService.add(this._$i18next('common:error'), this._$i18next('admin:account.cannotRetrieveDefaultParamList'), 'danger', 5000);
            this.loaded = true;
          });
      }, () => {
        this.loaded = true;
        this.notificationService.add(this._$i18next('common:error'), this._$i18next('admin:default.cannotRetrieveAvailableParams'), 'danger', 5000);
      });
    }, () => {
      this.notificationService.add(this._$i18next('common:error'), this._$i18next('admin:account.cannotRetrieveInstrumentList'), 'danger', 5000);
      this.loaded = true;
    });
  }

  public saveEmailNotifications() {
    this.defaultParametersSaving = true;
    this.defaultParameters.quoteTimes = this.adminService.toStringArray(this.editedPricingTime);
    this.defaultParameters.reportFormats = this.adminService.toStringArray(this.editedQuoteFormat);
    this.adminService.getDefaultParameterResource().save(this.defaultParameters, () => {
      this.notificationService.add(this._$i18next('common:success'), this._$i18next('admin:default.successfullySaved'), 'success', 5000);
      this.defaultParametersSaving = false;
    },
      () => {
        this.notificationService.add(this._$i18next('common:error'), this._$i18next('admin:default.cannotSaveNotif'), 'danger', 5000);
        this.defaultParametersSaving = false;
      });
  }

  public createInstrument(event: Event) {
    this.instrumentRefSetService.getAvailableRefSets().then((response: ng.IHttpPromiseCallbackArg<IInstrumentRefSet[]>) => {
      this.availableRefSets = response.data;

      this.selectedInstrument = null;

      this.editedInstrument = this.instrumentService.newInstrument();
      this.editedInstrument.delta = 0.01;
      this.editedInstrument.margin = 0.01;
      this.modalEditInstrument.show(event);
    });
  }

  public editInstrument(instrument: IInstrument, event: Event) {
    this.instrumentRefSetService.getAvailableRefSets().then((response: ng.IHttpPromiseCallbackArg<IInstrumentRefSet[]>) => {
      this.availableRefSets = response.data;
      this.selectedInstrument = instrument;
      this.editedInstrument = angular.copy(this.selectedInstrument);

      // set the refSet on edited instrument by getting one from the available refsets
      // because we want the drop down to be initialized. The instrument refSet we have by
      // using the angular.copy() is not equals to any 
      const foundRefSet: IInstrumentRefSet = this.availableRefSets.find(element => element.id === this.editedInstrument.instrumentRefSet.id);
      this.editedInstrument.instrumentRefSet = foundRefSet;

      this.modalEditInstrument.show(event);
    });
  }

  private intrumentAlreadyExists(tested: IInstrument): boolean {
    return this.instruments.find((value: ng.resource.IResource<IInstrument>) => {
      return (value as any).name === tested.name;
    }) !== undefined;
  }

  private isInstrumentFormValid(): boolean {
    return CURRENCY_PAIR_REGEX.test(this.editedInstrument.name)
      && this.editedInstrument.name.substring(0, 3) !== this.editedInstrument.name.substring(3)
      && ((!this.editedInstrument.displayedName || this.editedInstrument.displayedName.length === 0) || CURRENCY_PAIR_REGEX.test(this.editedInstrument.displayedName))
      && this.editedInstrument.precision > 0 && this.editedInstrument.precision < 10
      && this.editedInstrument.instrumentRefSet != null
      && (!this.isEditedInstrumentForBloomberg() || (this.isEditedInstrumentForBloomberg() && this.editedInstrument.bloombergTicker != null && 0 < this.editedInstrument.bloombergTicker.length));
  }

  public doEditInstrument(event: Event) {
    this.modalEditInstrumentLoading = true;
    this.editedInstrument.name = this.editedInstrument.name.replace('/', ''); // In case user write the '/' between currency pair
    if (this.editedInstrument.displayedName && this.editedInstrument.displayedName !== '') {
      this.editedInstrument.displayedName = this.editedInstrument.displayedName.replace('/', ''); // In case user write the '/' between currency pair
    } else {
      this.editedInstrument.displayedName = null;
    }

    if (!this.isEditedInstrumentForBloomberg()) {
      this.editedInstrument.bloombergTicker = null;
    }

    if (this.selectedInstrument === null && this.intrumentAlreadyExists(this.editedInstrument)) {
      this.closeEditModal(this._$i18next('admin:default.instrumentAlreadyExistTitle'), this._$i18next('admin:default.instrumentAlreadyExistMessage'), 'danger');
    } else {
      this.instrumentService.getInstrumentResource().save(this.editedInstrument,
        () => {
          if (this.selectedInstrument === null) { // create Instrument
            this.instruments.push(this.editedInstrument as any);
          } else { // edit Instrument
            angular.copy(this.editedInstrument, this.selectedInstrument);
          }
          this.closeEditModal(this._$i18next('common:success'), this._$i18next('admin:default.successfullySavedCcy'), 'success');
        }, () => {
          this.closeEditModal(this._$i18next('common:error'), this._$i18next('admin:default.cannotSaveCcy'), 'danger');
        });
    }
  }

  private closeEditModal(title: string, text: string, color: NotifType) {
    this.notificationService.add(title, text, color, 5000);
    this.modalEditInstrumentLoading = false;
    this.modalEditInstrument.hide();
  }

  public deleteInstrument(instrument: IInstrument, event: Event): void {
    this.selectedInstrument = instrument;
    this.selectedInstrumentAccounts = null;
    this.modalDeleteInstrument.show(event);
    this.clientService.getClientsByInstrument(instrument).then((response: ng.IHttpPromiseCallbackArg<IClient[]>) => {
      this.selectedInstrumentAccounts = response.data as any;
    }, () => {
      this.closeDeleteModal(this._$i18next('common:error'), this._$i18next('admin:account.cannotRetrieveAccountList'), 'danger');
    });
  }

  public doDeleteInstrument(): void {
    this.modalDeleteInstrumentLoading = true;
    this.instrumentService.getInstrumentResource().delete({ name: this.selectedInstrument.name },
      () => {
        this.instruments.splice(this.instruments.indexOf(this.selectedInstrument as any), 1);
        this.closeDeleteModal(this._$i18next('common:success'), this._$i18next('admin:default.successfullyDeleteInstrument'), 'success');
      }, (error: ng.IHttpPromiseCallbackArg<IClient>) => {
        this.closeDeleteModal(this._$i18next('common:error'), this._$i18next('admin:default.cannotDeleteInstrument'), 'danger');
      });
  }

  private closeDeleteModal(title: string, text: string, color: NotifType) {
    this.notificationService.add(title, text, color, 5000);
    this.modalDeleteInstrumentLoading = false;
    this.modalDeleteInstrument.hide();
  }

  public isEditedInstrumentForBloomberg(): boolean {
    const isEditedInstrumentForBloomberg: boolean =
       this.editedInstrument
       && this.editedInstrument.instrumentRefSet
       && this.editedInstrument.instrumentRefSet.source === 'BLOOMBERG';
    return isEditedInstrumentForBloomberg;
  }
}

angular.module('app')
  .controller('DefaultParametersController', DefaultParametersController);
