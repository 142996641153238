///<reference path="../../references.d.ts"/>

class User implements IUser {

  clients: IClient[];
  email: string;
  dailyNotification: boolean;
  reportFormats: string[];
  controlThatUserBelongsToClient: boolean;

  constructor() {
    this.dailyNotification = false;
    this.reportFormats = [];
    this.clients = [];
  }
}

class UserForm implements IUserForm {

  bdrs: number[];
  email: string;
  dailyNotification: boolean;
  reportFormats: string[];
  controlThatUserBelongsToClient: boolean;

  constructor() {
    this.reportFormats = [];
    this.bdrs = [];
    this.controlThatUserBelongsToClient = true;
  }
}

class UserService implements IUserService {
  private supportService: ISupportService;
  private userResource: IUserResource;
  private settingResource: any;

  constructor($resourceBuilder, SupportService: ISupportService) {
    this.supportService = SupportService;
    this.userResource = $resourceBuilder.build('user/:email');
    this.settingResource = $resourceBuilder.build('user/settings');
  }

  public getUserResource(): IUserResource {
    return this.userResource;
  }

  public getSettingResource(): any {
    return this.settingResource;
  }

  public getCurrent(successCallback?: Function, errorCallback?: Function): ng.resource.IResource<IUser> {
    let selectedUser = this.supportService.getSelectedUser();
    if (selectedUser) {
      return this.userResource.get({ 'email': selectedUser }, successCallback, errorCallback);
    }
    return this.userResource.get({ 'email': 'current' }, successCallback, errorCallback);
  }

  public newUser(): IUser {
    return new User();
  }

  private newUserForm(): IUserForm {
    return new UserForm();
  }

  public userFormFromUser(user: IUser): IUserForm {
    let userForm = this.newUserForm();
    if (user) {
      userForm.email = user.email;
      user.clients.forEach(client => userForm.bdrs.push(client.bdr));
      userForm.dailyNotification = user.dailyNotification;
      userForm.reportFormats = user.reportFormats;
    }
    return userForm;
  }

}
angular.module('app').service('UserService', UserService);
