///<reference path="../../references.d.ts"/>
/**
 * Show number as percentage
 */
angular.module('app')
  .filter('percentage', ['$filter', function ($filter: any): (input: number, decimals: number) => string {
    return function (input: number, decimals: number): string {
      if (angular.isUndefined(input) || input == null) {
        return '';
      }
      return $filter('number')(input * 100, decimals) + '%';
    };
  }])
  ;
