///<reference path="../../../references.d.ts"/>

/**
 * Global Admin controller 
 */

class AdminController {
  private _$scope: ng.IScope;
  private notificationService: INotificationService;


  constructor(NotificationService: INotificationService, $scope: ng.IScope) {
    this._$scope = $scope;
    this.notificationService = NotificationService;
  }

}

angular.module('app')
  .config(function ($routeProvider: ng.route.IRouteProvider): void {
    $routeProvider
      .when('/admin', {
        controller: 'AdminController',
        controllerAs: 'admin',
        templateUrl: './admin.html',
        reloadOnSearch: false
      })
      .otherwise({
        redirectTo: '/'
      });
  })
  .controller('AdminController', AdminController)
  ;
