///<reference path="../../references.d.ts"/>
angular.module('app')
    .directive('ezUnfluidifier', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attributes, formController) {
            $(element).find('nav div.container-fluid').removeClass('container-fluid').addClass('container');
        }
    };
});
