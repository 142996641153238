///<reference path="../../references.d.ts"/>
angular.module('app')
    .directive('menuCollapser', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attributes, formController) {
            $(element).on('click', function () {
                if ('matchMedia' in window) {
                    if (window.matchMedia('(max-width:767px)').matches) {
                        $('.navbar-toggle').click();
                    }
                }
            });
        }
    };
});
