///<reference path="../../../references.d.ts"/>

/**
 * Global home controller
 */

class HomeController {
  private _$scope: ng.IScope;
  private _$i18next: any;
  private _$filter: ng.IFilterService;
  private clientService: IClientService;
  private notificationService: INotificationService;
  private snapshotService: ISnapshotService;
  private supportService: ISupportService;
  private userService: IUserService;
  private ezUsers: ezWeb.auth.IEzUsersService;
  private loaded: boolean;
  private exceedEuroAmount: boolean = false;

  private availableClients: IClient[];
  private selectedClient: IClient;
  private shouldExportAsClient: boolean;

  private snapshotList: ILightSnapshot[];

  private availableSnapshots: ISnapshotWrapper[];

  private selectedSnapshotWrapper: ISnapshotWrapper; // Current loaded snapshot
  private selectedSnapshotId: string; // Model for snapshot picker

  // Reference model calculated from received snapshot
  private quotesModel: { [key: string]: IEnhancedQuote[] };

  // Used to list all formats available for export
  private availableParameters: ng.resource.IResource<IAvailableParameters>;

  // Vars used for 'New Grid' feature
  private newGridModalApi: IEzModalApi;
  private createNewGridLoading: boolean;

  // Vars used for 'Notify Account' feature
  private notifyAccountModalApi: IEzModalApi;
  private notifyAccountLoading: boolean;
  private nbClientsForNotif: number; // number displayed on modal button
  private selectedClientsForNotif: { [brd: string]: boolean }; // map of <bdr as string,boolean>. Due to html template building, bdr are not numbers here

  /* Calculator Model */
  private calcAmount: number; // Amount input model
  private selectedQuote: IEnhancedQuote; // Model for first (I would like to send) calculator dropdown
  private selectedPivotCurrency: string; // Model for second (Against) calculator dropdown
  private availablePivots = [];

  private clients: ng.resource.IResourceArray<ng.resource.IResource<IClient>>; // Client list for sales
  private filteredClients: IClient[]; // Represents filtered client inside the notify Account modal
  private quoteTimeClients: { [quoteTime: string]: IClient[] }; // Keep clients grouped by quoteTime
  private clientModalApi: IEzModalApi; // Modal where clients are used
  private currentClientIndex: number = 0; // Used by pagination on client modal

  private isWatchActive = false;

  public appFileSaver;

  public isRegeneratingGrid: boolean = false;

  private isNavigatingAsOnContact: boolean;

  constructor(
    ClientService: IClientService,
    NotificationService: INotificationService,
    $scope: ng.IScope,
    $filter: ng.IFilterService,
    SnapshotService: ISnapshotService,
    UserService: IUserService,
    SupportService: ISupportService,
    AdminService: IAdminService,
    $i18next: Function,
    $appFileSaver,
    ezUsers: ezWeb.auth.IEzUsersService
  ) {
    this._$scope = $scope;
    this._$i18next = $i18next;
    this._$filter = $filter;
    this.clientService = ClientService;
    this.notificationService = NotificationService;
    this.snapshotService = SnapshotService;
    this.supportService = SupportService;
    this.appFileSaver = $appFileSaver;
    this.userService = UserService;
    this.ezUsers = ezUsers;

    this.availableParameters = AdminService.getParameterResource().get(
      () => {},
      () => {
        this.notificationService.add(
          this._$i18next("common:error"),
          this._$i18next("admin:account.cannotRetrieveParameters"),
          "danger",
          5000
        );
      }
    );

    let username = window.sessionStorage.getItem("selectedUser");
    this.isNavigatingAsOnContact = username != null && 0 < username.length;

    this.loadPage();

    // When selected snapshot change, load selected one
    $scope.$watch(
      () => {
        return this.selectedSnapshotId;
      },
      () => {
        if (this.selectedSnapshotId && this.isWatchActive) {
          const selectedSnapshot = this.snapshotList.find(
            (s) => s.id === this.selectedSnapshotId
          );

          this.loadAndDisplaySnapshot(
            this.selectedSnapshotId,
            selectedSnapshot ? selectedSnapshot.clientId : null
          );
        }
      }
    );

    // When user change left/right or both currency
    $scope.$on(
      "instrumentSelected",
      (event: ng.IAngularEvent, quote: IEnhancedQuote) => {
        this.selectedPivotCurrency = quote.leftCurrency;
        this.selectedQuote = quote;
      }
    );
  }

  private loadPage(): void {
    this.userService.getCurrent((user: IUser) => {
      if (user.clients) {
        this.availableClients = this.sortClientsList(user.clients);
        this.selectedClient = this.availableClients[0];

        // Load snapshot list for the selected client and display most recent snapshot
        this.loadSnapshotListAndDisplayMostRecent(this.selectedClient.bdr);
      } else {
        this.loadSnapshotListAndDisplayMostRecent(null);
      }
    });
  }

  private sortClientsList(clientsList: IClient[]): IClient[] {
    return clientsList.sort((a, b) => {
      const aBicAsLowerCase = a.bic.toLocaleLowerCase();
      const bBicAsLowerCase = b.bic.toLocaleLowerCase();
      return aBicAsLowerCase.localeCompare(bBicAsLowerCase);
    });
  }

  private loadSnapshotListAndDisplayMostRecent(clientId: number): void {
    this.loaded = false;

    this.snapshotService
      .getSnapshotsList(clientId)
      .then((response: ng.IHttpPromiseCallbackArg<ILightSnapshot[]>) => {
        this.snapshotList = response.data;

        if (this.snapshotList && this.snapshotList.length > 0) {
          this.loadAndDisplaySnapshot(
            this.snapshotList[0].id,
            this.snapshotList[0].clientId
          );
        }
      })
      .finally(() => {
        this.loaded = true;
      });
  }

  private loadAndDisplaySnapshot(snapshotId: string, clientId?: number): void {
    this.loaded = false;

    this.isWatchActive = false; // <-- disable scope watcher before changing selectedSnapshotId to avoid the function to be called twice
    this.selectedSnapshotId = snapshotId;

    this.snapshotService
      .getSnapshot(snapshotId, clientId)
      .then(
        (snapshotsResponse: ng.IHttpPromiseCallbackArg<ISnapshotWrapper[]>) => {
          this.availableSnapshots = snapshotsResponse.data;
          this.selectedSnapshotWrapper = this.availableSnapshots[0];
          this.updateDisplayedSnapshot();
        },
        () => {
          this.notificationService.add(
            this._$i18next("common:error"),
            this._$i18next("home:cannotRetrieveSnapshot"),
            "danger",
            5000
          );
        }
      )
      .finally(() => {
        this.isWatchActive = true; // <-- re-enable scope watcher after function is complete
        this.loaded = true;
      });
  }

  private selectClient(client: IClient) {
    if (client) {
      this.loadSnapshotListAndDisplayMostRecent(client.bdr);
    }
  }

  public exportAllMargedAndNonMargedGridsAsSales($event) {
    const idToUse: string = this.selectedSnapshotId;
    const locale: string = this._$i18next.options.lng.substring(0, 2);
    const url = this.supportService.addSelectedUserToUrlIfAny(
      `export/grids-as-sales/${idToUse}/all?locale=${locale}`
    );
    this.appFileSaver.openExcel($event, url);
  }

  public exportGridAsSales($event, format: string) {
    const idToUse: string = this.selectedSnapshotId;
    const gridName: string = this.selectedSnapshotWrapper.name;
    const locale: string = this._$i18next.options.lng.substring(0, 2);
    const url = this.supportService.addSelectedUserToUrlIfAny(
      `export/grids-as-sales/${idToUse}/${gridName}?format=${format}&locale=${locale}`
    );
    this.appFileSaver.openExcel($event, url);
  }

  public exportClientGridAsSales($event, format: string) {
    const idToUse: string = this.selectedSnapshotId;
    const locale: string = this._$i18next.options.lng.substring(0, 2);
    const bdr: number = this.selectedClient.bdr;
    const url = this.supportService.addSelectedUserToUrlIfAny(
      `export/grids-as-sales-for-client/${bdr}/${idToUse}?format=${format}&locale=${locale}`
    );
    this.appFileSaver.openExcel($event, url);
  }

  public exportGridAsContact($event, format: string) {
    const idToUse: string = this.selectedSnapshotId;
    const locale: string = this._$i18next.options.lng.substring(0, 2);
    const url = this.supportService.addSelectedUserToUrlIfAny(
      `export/grids-as-contact/${idToUse}?format=${format}&locale=${locale}`
    );
    this.appFileSaver.openExcel($event, url);
  }

  public userAsExportAsContactRole(): boolean {
    const user = this.ezUsers as any;
    return user.roles.indexOf("api.sgm-payfx-back_EXPORT_AS_CONTACT") > -1;
  }

  /**
   * Force quotes order EUR, GBP, USD, CHF, CAD
   * @param {{[p: string]: IEnhancedQuote[]}} quotesModel
   * @returns {{[p: string]: IEnhancedQuote[]}}
   */
  private orderQuotesModel(quotesModel: { [key: string]: IEnhancedQuote[] }) {
    let orderedQuotesModel: { [key: string]: IEnhancedQuote[] } = {};
    const orderedPivotCcy = ["EUR", "GBP", "USD", "CHF", "CAD", "SGD"];
    orderedPivotCcy.forEach((pivot) => {
      const listOfCCy = quotesModel[pivot];
      if (listOfCCy && listOfCCy.length > 0) {
        this.availablePivots.push(pivot);
        orderedQuotesModel[pivot] = quotesModel[pivot];
      }
    });

    Object.keys(quotesModel)
      .sort()
      .forEach(function (key) {
        let value = quotesModel[key];
        orderedQuotesModel[key] = value;
      });
    return orderedQuotesModel;
  }

  private updateDisplayedSnapshot() {
    this.quotesModel = {};
    if (this.selectedSnapshotWrapper) {
      this.selectedSnapshotWrapper.snapshot.quotes.forEach((quote: IQuote) => {
        let eQuote: IEnhancedQuote = this.snapshotService.enhanceQuote(quote);
        // Build margedQuotes Map
        if (!this.quotesModel[eQuote.leftCurrency]) {
          this.quotesModel[eQuote.leftCurrency] = [];
        }
        this.quotesModel[eQuote.leftCurrency].push(eQuote);
      });
      // force order
      this.quotesModel = this.orderQuotesModel(this.quotesModel);
      // Select a pivot currency
      this.selectedPivotCurrency = Object.keys(this.quotesModel)[0];
      this.selectDefaultQuote();
    }
  }

  private onGridSelection() {
    this.updateDisplayedSnapshot();
  }


  // On selection of a pivot currency (init and html)
  private selectDefaultQuote() {
    let availableQuotes = this.quotesModel[this.selectedPivotCurrency];
    if (availableQuotes) {
      if (!this.selectedQuote) {
        this._$scope.$broadcast("instrumentSelected", availableQuotes[0]);
      } else {
        let foundQuote = availableQuotes.find((quote: IEnhancedQuote) => {
          return quote.rightCurrency === this.selectedQuote.rightCurrency;
        });
        this._$scope.$broadcast(
          "instrumentSelected",
          foundQuote ? foundQuote : availableQuotes[0]
        );
      }
    }
  }

  private calculatorExceedEuroAmountMessage(calcAmount: any) {
    let euroQuote = this.quotesModel["EUR"];
    let foundQuote = euroQuote.find((quote: IEnhancedQuote) => {
      return quote.rightCurrency === this.selectedQuote.rightCurrency;
    });
    let euroCurrency = foundQuote ? foundQuote : euroQuote[0];
    let euroAmount = calcAmount / euroCurrency.value;
    if (euroAmount > 300000) {
      this.exceedEuroAmount = true;
    } else this.exceedEuroAmount = false;
  }

  // On click on a reference quote row (html)
  public selectQuote(quote: IEnhancedQuote) {
    this._$scope.$broadcast("instrumentSelected", quote);
  }

  public openClientModal(event: Event): void {
    this.initClientModalClients();
    this.currentClientIndex = 0;
    this.clientModalApi.show(event);
  }

  public regenerateGrid(event: Event): void {
    this.isRegeneratingGrid = true;
    this.snapshotService
      .regenerateGrid()
      .then(() => {
        this.notificationService.add(
          this._$i18next("common:success"),
          this._$i18next("home:regenerationSuccessfully"),
          "success",
          5000
        );
        this.loadPage();
      })
      .catch((err) => {
        this.notificationService.add(
          this._$i18next("common:error"),
          this._$i18next("home:cannotRegenerateGrid"),
          "danger",
          5000
        );
      })
      .finally(() => {
        this.isRegeneratingGrid = false;
      });
  }

  private initClientModalClients() {
    if (!this.clients) {
      this.clients = this.clientService
        .getClientResource()
        .query((clients: IClient[]) => {
          this.filteredClients = this._$filter("orderBy")(
            clients,
            "cambistName"
          );
        });
    } else {
      this.filteredClients = this._$filter("orderBy")(
        this.clients,
        "cambistName"
      );
    }
  }

  private initQuoteTimeClients(clients: IClient[]) {
    this.quoteTimeClients["AM_02"] = this.getQuoteTimeClients(clients, "AM_02");
    this.quoteTimeClients["AM_08"] = this.getQuoteTimeClients(clients, "AM_08");
    this.quoteTimeClients["PM_12"] = this.getQuoteTimeClients(clients, "PM_12");
    this.quoteTimeClients["PM_02"] = this.getQuoteTimeClients(clients, "PM_02");
    this.quoteTimeClients["PM_05"] = this.getQuoteTimeClients(clients, "PM_05");
  }

  private getQuoteTimeClients(
    clients: IClient[],
    quoteTime: string
  ): IClient[] {
    return clients.filter(
      (client) =>
        client.quoteTimes.findIndex(
          (clientQuoteTime) => clientQuoteTime === quoteTime
        ) !== -1
    );
  }

  // Action button on clientModal
  public chooseClient(client: IClient): void {
    this.selectedClient = client;
    this.shouldExportAsClient = true;

    this.selectClient(client);

    this.clientModalApi.hide();
  }

  private unchooseClient(event: Event): void {
    this.selectedClient = null;
    this.shouldExportAsClient = false;
    this.loadPage();
  }

  // Action button on newGridModal
  public createNewGrid(): void {
    this.createNewGridLoading = true;
    this.snapshotService
      .createRaws()
      .then((response: ng.IHttpPromiseCallbackArg<ISnapshotWrapper[]>) => {
        // Display created raws.
        this.loadPage();
      })
      .catch(() => {
        this.notificationService.add(
          this._$i18next("common:error"),
          this._$i18next("home:cannotCreateSnapshot"),
          "danger",
          5000
        );
      })
      .finally(() => {
        this.createNewGridLoading = false;
        this.newGridModalApi.hide();
      });
  }

  private initNotifyAccountModalClients() {
    if (!this.clients) {
      this.clients = this.clientService
        .getClientResource()
        .query((clients: IClient[]) => {
          this.filteredClients = this._$filter("orderBy")(
            clients,
            "cambistName"
          );
          this.initQuoteTimeClients(this.filteredClients);
        });
    } else {
      this.filteredClients = this._$filter("orderBy")(
        this.clients,
        "cambistName"
      );
      this.initQuoteTimeClients(this.filteredClients);
    }
  }

  public openNotifyAccountModal(event: Event): void {
    this.quoteTimeClients = {};
    this.initNotifyAccountModalClients();
    this.nbClientsForNotif = 0;
    this.selectedClientsForNotif = {};
    this.currentClientIndex = 0;
    this.notifyAccountModalApi.show(event);
  }

  public filterClient(query: string): void {
    this.currentClientIndex = 0;
    this.filteredClients = this._$filter("orderBy")(
      this._$filter("filter")(this.clients, query),
      "cambistName"
    );
  }

  // Action buttons 'AM_02, AM_08, PM_12, PM_02 and PM_05' on notifyAccountModal
  public selectAllClientsToNotifAtQuoteTime(
    quoteTime: string,
    state: boolean
  ): void {
    this.quoteTimeClients[quoteTime].forEach(
      (client) => (this.selectedClientsForNotif[client.bdr] = state)
    );

    this.nbClientsForNotif = Object.keys(this.selectedClientsForNotif).filter(
      (key) => this.selectedClientsForNotif[key]
    ).length;
  }

  // Action button 'send' on notifyAccountModal
  public notifyAccounts(): void {
    let bdrs: number[] = Object.keys(this.selectedClientsForNotif)
      .filter((key: string): boolean => {
        return this.selectedClientsForNotif[key];
      })
      .map(Number);

    this.snapshotService
      .createSnapshotForClients(
        this.selectedSnapshotWrapper.snapshot.snapshotDateTime,
        bdrs
      )
      .then(() => {
        this.notificationService.add(
          this._$i18next("common:success"),
          this._$i18next("home:batchSuccessfullyCreated"),
          "success",
          5000
        );
      })
      .catch(() => {
        this.notificationService.add(
          this._$i18next("common:error"),
          this._$i18next("home:cannotCreateBatch"),
          "danger",
          5000
        );
      })
      .finally(() => {
        this.notifyAccountModalApi.hide();
      });
  }
}

angular
  .module("app")
  .config(function ($routeProvider: ng.route.IRouteProvider): void {
    $routeProvider
      .when("/", {
        controller: "HomeController",
        controllerAs: "home",
        templateUrl: "./home.html",
        reloadOnSearch: false,
      })
      .otherwise({
        redirectTo: "/",
      });
  })
  .controller("HomeController", HomeController);
