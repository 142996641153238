///<reference path="../../references.d.ts"/>
/**
 * Show string arrays as a flat comma separated string
 */
angular.module('app')
  .filter('flattenStringArray', function (): (input: string[]) => string {
    return function (input: string[]): string {
      if (angular.isUndefined(input) || input == null) {
        return '';
      }
      if (!angular.isArray(input)) {
        return  input as any;
      }
      let result = '';
      input.forEach((part: string) => {
        result = result + part + ', ';
      });
      return result.substring(0, result.length - 2);
    };
  }).filter('flattenArray', function (): (input: Object[], field: string) => string {
    return function (input: Object[], field: string): string {
      if (angular.isUndefined(input) || input == null) {
        return '';
      }
      if (!angular.isArray(input)) {
        return  input as any;
      }
      let result = '';
      input.forEach((part: any) => {
        result = result + part[field] + ', ';
      });
      return result.substring(0, result.length - 2);
    };
  })
  ;
