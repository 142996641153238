///<reference path="../../../references.d.ts"/>
/**
 * Global Admin controller
 */
var AdminController = (function () {
    function AdminController(NotificationService, $scope) {
        this._$scope = $scope;
        this.notificationService = NotificationService;
    }
    return AdminController;
}());
angular.module('app')
    .config(function ($routeProvider) {
    $routeProvider
        .when('/admin', {
        controller: 'AdminController',
        controllerAs: 'admin',
        templateUrl: "client/features/admin/admin.html",
        reloadOnSearch: false
    })
        .otherwise({
        redirectTo: '/'
    });
})
    .controller('AdminController', AdminController);
