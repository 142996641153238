/// <reference path="../../references.d.ts"/>

class EzNotification implements ezWeb.core.IEzNotification {
  title: string;
  text: string;
  importance: string;
  read: false;
  $read: () => void;
  $remove: () => void;

  constructor(title: string, text: string, importance: string) {
    this.title = title;
    this.text = text;
    this.importance = importance;
  }
}

/**
 * Wraps EzNotifications for messages to the user
 */
class NotificationService implements INotificationService {
  private ezNotifications: ezWeb.core.IEzNotificationsFactory;
  private $timeout: ng.ITimeoutService;

  constructor(ezNotifications: ezWeb.core.IEzNotificationsFactory, $timeout: ng.ITimeoutService) {
    this.$timeout = $timeout;
    this.ezNotifications = ezNotifications;
  }

  public add(title: string, text: string, importance: NotifType, nbTimeout?: number): void {
    let notif = this.ezNotifications.add(new EzNotification(title, text, importance));
    if (nbTimeout) {
      this.$timeout(() => {
        this.ezNotifications.remove(notif.id);
      }, nbTimeout);
    }
  }

}

angular.module('app').service('NotificationService', NotificationService);
