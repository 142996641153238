///<reference path="../../references.d.ts"/>

import IRequestShortcutConfig = angular.IRequestShortcutConfig;

class Client implements IClient {

  bdr: number;
  mnenmonicBdr: string;
  cambistName: string;
  bic: string;
  emailTemplate: string;
  reportName: string;
  quoteTimes: string[];
  reportFormats: string[];
  instruments: string[];

  constructor() {
    this.quoteTimes = [];
    this.reportFormats = [];
    this.instruments = [];
  }
}

class ClientId implements IClientId {
  bdr: number;
  cambistName: string;
  icId: string;
}

class ClientService implements IClientService {

  _$http: ng.IHttpService;
  private clientResource: ng.resource.IResourceClass<ng.resource.IResource<IClient>>;

  constructor($resourceBuilder, extendedHttpService: ng.IHttpService) {
    this._$http = extendedHttpService;
    this.clientResource = $resourceBuilder.build('client/:bdr');
  }

  public getClientResource(): ng.resource.IResourceClass<ng.resource.IResource<IClient>> {
    return this.clientResource;
  }

  public getClientsByInstrument(instrument: IInstrument): ng.IHttpPromise<IClient[]> {
    return this._$http.get('client/instrument/' + instrument.name);
  }

  public getClientIcId(bdr: number): ng.IHttpPromise<string> {
    return this._$http.get(`client/ic/id/${bdr}`, { responseType: 'text' });
  }

  public getNowAsString(datePattern: string): ng.IHttpPromise<string> {
    return this._$http.get(`client/now/${datePattern}`, { responseType: 'text' });
  }

  public newClient(): IClient {
    return new Client();
  }

  public newClientId(): IClientId {
    return new ClientId();
  }

}
angular.module('app').service('ClientService', ClientService);
