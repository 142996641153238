///<reference path="../references.d.ts"/>


const sgwtConnectInterceptor = (sgwtConnectProvider) => {
  const wrapper = (window as any);
  sgwtConnectProvider.use(wrapper.sgwtConnect, ({url}: angular.IRequestConfig) => {
    return url.indexOf(wrapper.appConfig.backend_url) === 0;
  });
};

const locationProviderConfigurer = ($locationProvider) => {
  $locationProvider.hashPrefix('');
};

const ezWebConfigurer = (ezUsersProvider: ezWeb.auth.IEzUsersProvider,
  ezPostAuthenticationBackendProvider: ezWeb.auth.IEzPostAuthenticationBackendProvider): void => {
  ezPostAuthenticationBackendProvider.configure({
    rememberMeParameter: 'false',
    currentUserUri: (window as any).appConfig.backend_url + 'authentication/current'
  });
  ezUsersProvider.configure('ezPostAuthenticationBackend');
};

const errorHandlerInterceptor = ($httpProvider: ng.IHttpProvider) => {
  $httpProvider.interceptors.push(function ($location: ng.ILocationService, $q: ng.IQService, $window: ng.IWindowService) {
    return {
      responseError: function (response: ng.IHttpPromiseCallbackArg<any>) {
        let status = response.status;
        if (status === 401) {
          // the actual session has expired. we redirect the user to the login form.
          if (status === 401) {
            // the actual session has expired. we redirect the user to the login form.
            if (response.data.SsoRedirectUrl) {
              $window.location.replace(response.data.SsoRedirectUrl);
            }
          }
        }
        return $q.reject(response);
      }
    };
  });
};

const computeUrl = ($window) => {
  return (path) => {
      return $window.appConfig.backend_url + path;
  };
};

const resourceBuilder = ($computeUrl, $resource: ng.resource.IResourceService) => {
  return {
    build: (path) => {
      return $resource($computeUrl(path));
    }
  };
};

const extendedHttpService = ($computeUrl, $http) => {
  return {
    get: (url: string, other) => {
      return $http.get($computeUrl(url), other);
    },
    post: (url: string, other) => {
      return $http.post($computeUrl(url), other);
    },
    head: (url: string) => {
      return $http.head($computeUrl(url));
    }
  };
};

const piwikConfigurer = ($window: ng.IWindowService, ezAnalytics: ezWeb.analytics.IEzAnalyticsFactory, ezUsers: ezWeb.auth.IEzUsersService) => {
  return (config) => {
    // Setup for piwik
    if (config.environment === 'GULP') {
      ezAnalytics.suppress();
    } else {
      ezUsers.get(() => {
        ($window as any)._paq = ($window as any)._paq || [];
        ($window as any)._paq.push(['requireCookieConsent']); // That must be track before the trackPageView.
        ($window as any)._paq.push(['trackPageView']);
        ($window as any)._paq.push(['setUserId', ezUsers.email]);
        ezAnalytics.start(config.piwik_url, config.site_id);
        listenCookiesUserAgreement($window);
      });
    }
  };
};

const listenCookiesUserAgreement = function($window) {
  ($window as any).didomiOnReady = ($window as any).didomiOnReady || [];
  ($window as any).didomiOnReady.push(() => {
  const widget = document.querySelector('sgwt-mini-footer');
  widget.addEventListener('sgwt-mini-footer--user-consent-changed', event => {
    // You get all the details of ALL user consents from the event detail property:      
    // You can also check the consent through our helper functions:
    (widget as any).getUserConsentOnMatomo().then(consent => updateMatomoUserAcceptance($window, consent));
  });
});
};

const updateMatomoUserAcceptance = function($window, consent) {
if (consent) {
  ($window as any)._paq.push(['rememberCookieConsentGiven', 4320]); // 4320 = retention period of the cookie for 6 month
  ($window as any)._paq.push(['setCustomDimension', 10, true]);
} else {
  ($window as any)._paq.push(['setCustomDimension', 10, false]);
  ($window as any)._paq.push(['forgetCookieConsentGiven']);
}
} ;

const appFileSaver = (FileSaver, extendedHttpService) => {
  return {
    openExcel($event, url: string) {
      // Prevent default behavior when clicking a link
      $event.preventDefault();
      extendedHttpService.get(url, {responseType: 'arraybuffer'})
      .then(function (response) {
        const blob = new Blob([response.data], {type: 'application/zip'});
        const left = 'attachment; filename="';
        const dispo = response.headers('content-disposition');
        FileSaver.saveAs(blob, dispo.substring(left.length, dispo.length - '"'.length));
      });
    }
  };
};
window.ezweb.start() //
  .config(ezWebConfigurer)
  .config(['$locationProvider', locationProviderConfigurer])
  .config(sgwtConnectInterceptor)
  // Redirect when 401 unauthorized
  .config(errorHandlerInterceptor)
  .factory('$computeUrl', computeUrl)
  .factory('$resourceBuilder', resourceBuilder)
  .factory('extendedHttpService', extendedHttpService)
  .factory('$piwikConfigurer', piwikConfigurer)
  .factory('$appFileSaver', appFileSaver)
  /* tslint:enable */
  .run(function (ezAppScope: ezWeb.core.IEzAppScopeFactory, $piwikConfigurer, $window: ng.IWindowService) {
    const config = $window.appConfig;
    ezAppScope.ensure('envconfig', config);
    $piwikConfigurer(config);
  });