///<reference path="../../../references.d.ts"/>
var MainLayoutController = (function () {
    function MainLayoutController() {
    }
    return MainLayoutController;
}());
var HeaderController = (function () {
    function HeaderController($scope, ezI18nService, $i18next, $location, ezUsers, SupportService, ezAppScope) {
        var _this = this;
        this.changeLanguage = function (event) {
            this.currentLanguage = event.detail.language;
            this.ezI18nService.changeLanguage(event.detail.language);
        };
        this.startNavigateAs = function () {
            this.navigateAsModal.show();
        };
        this.stopNavigateAs = function () {
            this.supportService.setSelectedUser(undefined);
            this.supportService.deleteSelectedUserIfAny();
            this.selectedUser = {
                name: undefined,
            };
            // go to home page
            this._$location.path("/");
            window.location.reload();
        };
        this._$location = $location;
        this.ezUsers = ezUsers;
        this.ezAppScope = ezAppScope;
        this.ezI18nService = ezI18nService;
        this.supportService = SupportService;
        this.supportService.loadSelectedUserIfAny(ezUsers);
        this.currentLanguage = $i18next.options.lng;
        if (this.supportService.getSelectedUser()) {
            this.selectedUser = {
                name: this.supportService.getSelectedUser(),
            };
        }
        var modalListener = $scope.$watchCollection(function () { return [_this.ezUsers.connected]; }, function () {
            var user = _this.ezUsers;
            // current environment
            var envName = window.sessionStorage.getItem("environment");
            _this.environmentName = envName === "production" ? undefined : envName;
            _this.navigateAsAttribute =
                user.roles.indexOf("api.sgm-payfx-back_NAVIGATE_AS") > -1 ||
                    user.roles.indexOf("api.sgm-payfx-back_NAVIGATE_AS_CLIENT") > -1
                    ? "whatever"
                    : undefined;
            var accountCenter = document.querySelector("#my-account-center");
            if (accountCenter && _this.navigateAsAttribute) {
                accountCenter.setAttribute("navigate-as", _this.navigateAsAttribute);
            }
        });
    }
    return HeaderController;
}());
var FooterController = (function () {
    function FooterController($scope, $i18next) {
        var en = "'en':[]";
        var fr = "'fr':[]";
        this.legalNotices = "{" + en + "," + fr + "}";
    }
    return FooterController;
}());
angular
    .module("app")
    .config(function (ezLayoutProvider) {
    var layoutName = "main";
    ezLayoutProvider.register(layoutName, { templateUrl: "client/layouts/main/main.html" });
    ezLayoutProvider.setDefault(layoutName);
})
    .controller("MainLayoutController", MainLayoutController)
    .controller("HeaderController", HeaderController)
    .controller("FooterController", FooterController);
