///<reference path="../../references.d.ts"/>

class SupportService implements ISupportService {
  private _$http: ng.IHttpService;
  private ezAppScope: ezWeb.core.IEzAppScopeFactory;

  constructor(
    extendedHttpService: ng.IHttpService,
    ezAppScope: ezWeb.core.IEzAppScopeFactory
  ) {
    this._$http = extendedHttpService;
    this.ezAppScope = ezAppScope;
  }

  public getUsersForSupport(): ng.IHttpPromise<IUser[]> {
    return this._$http.get("support/user");
  }

  public getSelectedUser(): string {
    return window.sessionStorage.getItem("selectedUser");
  }

  public setSelectedUser(username: string): void {
    window.sessionStorage.setItem("selectedUser", username);
  }

  public loadSelectedUserIfAny(ezUsers: ezWeb.auth.IEzUsers): string {
    let username = window.sessionStorage.getItem("selectedUser");
    if (username) {
      this._$http
        .get(`support/user/${username}/role`)
        .then((response: ng.IHttpPromiseCallbackArg<string[]>) => {
          if (response.data && response.data.length > 0) {
            ezUsers.roles = ["api.sgm-payfx-back_NAVIGATE_AS"].concat(
              response.data
            );
          }
        });
      this.ezAppScope.ensure("selectedUser", {
        mail: username,
      });
    }
    return username;
  }

  public deleteSelectedUserIfAny(): void {
    window.sessionStorage.removeItem("selectedUser");
    delete (<any>this.ezAppScope).selectedUser;
  }

  public addSelectedUserToUrlIfAny(url: string): string {
    let username = window.sessionStorage.getItem("selectedUser");
    if (!username) {
      return url;
    }
    let newUrl = url.indexOf("?") > -1 ? url + "&" : url + "?";
    newUrl += "selectedUser=" + username;
    return newUrl;
  }
}

angular.module("app").service("SupportService", SupportService);
