///<reference path="../../../references.d.ts"/>
/**
 * Global home controller
 */
var HomeController = (function () {
    function HomeController(ClientService, NotificationService, $scope, $filter, SnapshotService, UserService, SupportService, AdminService, $i18next, $appFileSaver, ezUsers) {
        var _this = this;
        this.exceedEuroAmount = false;
        this.availablePivots = [];
        this.currentClientIndex = 0; // Used by pagination on client modal
        this.isWatchActive = false;
        this.isRegeneratingGrid = false;
        this._$scope = $scope;
        this._$i18next = $i18next;
        this._$filter = $filter;
        this.clientService = ClientService;
        this.notificationService = NotificationService;
        this.snapshotService = SnapshotService;
        this.supportService = SupportService;
        this.appFileSaver = $appFileSaver;
        this.userService = UserService;
        this.ezUsers = ezUsers;
        this.availableParameters = AdminService.getParameterResource().get(function () { }, function () {
            _this.notificationService.add(_this._$i18next("common:error"), _this._$i18next("admin:account.cannotRetrieveParameters"), "danger", 5000);
        });
        var username = window.sessionStorage.getItem("selectedUser");
        this.isNavigatingAsOnContact = username != null && 0 < username.length;
        this.loadPage();
        // When selected snapshot change, load selected one
        $scope.$watch(function () {
            return _this.selectedSnapshotId;
        }, function () {
            if (_this.selectedSnapshotId && _this.isWatchActive) {
                var selectedSnapshot = _this.snapshotList.find(function (s) { return s.id === _this.selectedSnapshotId; });
                _this.loadAndDisplaySnapshot(_this.selectedSnapshotId, selectedSnapshot ? selectedSnapshot.clientId : null);
            }
        });
        // When user change left/right or both currency
        $scope.$on("instrumentSelected", function (event, quote) {
            _this.selectedPivotCurrency = quote.leftCurrency;
            _this.selectedQuote = quote;
        });
    }
    HomeController.prototype.loadPage = function () {
        var _this = this;
        this.userService.getCurrent(function (user) {
            if (user.clients) {
                _this.availableClients = _this.sortClientsList(user.clients);
                _this.selectedClient = _this.availableClients[0];
                // Load snapshot list for the selected client and display most recent snapshot
                _this.loadSnapshotListAndDisplayMostRecent(_this.selectedClient.bdr);
            }
            else {
                _this.loadSnapshotListAndDisplayMostRecent(null);
            }
        });
    };
    HomeController.prototype.sortClientsList = function (clientsList) {
        return clientsList.sort(function (a, b) {
            var aBicAsLowerCase = a.bic.toLocaleLowerCase();
            var bBicAsLowerCase = b.bic.toLocaleLowerCase();
            return aBicAsLowerCase.localeCompare(bBicAsLowerCase);
        });
    };
    HomeController.prototype.loadSnapshotListAndDisplayMostRecent = function (clientId) {
        var _this = this;
        this.loaded = false;
        this.snapshotService
            .getSnapshotsList(clientId)
            .then(function (response) {
            _this.snapshotList = response.data;
            if (_this.snapshotList && _this.snapshotList.length > 0) {
                _this.loadAndDisplaySnapshot(_this.snapshotList[0].id, _this.snapshotList[0].clientId);
            }
        })
            .finally(function () {
            _this.loaded = true;
        });
    };
    HomeController.prototype.loadAndDisplaySnapshot = function (snapshotId, clientId) {
        var _this = this;
        this.loaded = false;
        this.isWatchActive = false; // <-- disable scope watcher before changing selectedSnapshotId to avoid the function to be called twice
        this.selectedSnapshotId = snapshotId;
        this.snapshotService
            .getSnapshot(snapshotId, clientId)
            .then(function (snapshotsResponse) {
            _this.availableSnapshots = snapshotsResponse.data;
            _this.selectedSnapshotWrapper = _this.availableSnapshots[0];
            _this.updateDisplayedSnapshot();
        }, function () {
            _this.notificationService.add(_this._$i18next("common:error"), _this._$i18next("home:cannotRetrieveSnapshot"), "danger", 5000);
        })
            .finally(function () {
            _this.isWatchActive = true; // <-- re-enable scope watcher after function is complete
            _this.loaded = true;
        });
    };
    HomeController.prototype.selectClient = function (client) {
        if (client) {
            this.loadSnapshotListAndDisplayMostRecent(client.bdr);
        }
    };
    HomeController.prototype.exportAllMargedAndNonMargedGridsAsSales = function ($event) {
        var idToUse = this.selectedSnapshotId;
        var locale = this._$i18next.options.lng.substring(0, 2);
        var url = this.supportService.addSelectedUserToUrlIfAny("export/grids-as-sales/" + idToUse + "/all?locale=" + locale);
        this.appFileSaver.openExcel($event, url);
    };
    HomeController.prototype.exportGridAsSales = function ($event, format) {
        var idToUse = this.selectedSnapshotId;
        var gridName = this.selectedSnapshotWrapper.name;
        var locale = this._$i18next.options.lng.substring(0, 2);
        var url = this.supportService.addSelectedUserToUrlIfAny("export/grids-as-sales/" + idToUse + "/" + gridName + "?format=" + format + "&locale=" + locale);
        this.appFileSaver.openExcel($event, url);
    };
    HomeController.prototype.exportClientGridAsSales = function ($event, format) {
        var idToUse = this.selectedSnapshotId;
        var locale = this._$i18next.options.lng.substring(0, 2);
        var bdr = this.selectedClient.bdr;
        var url = this.supportService.addSelectedUserToUrlIfAny("export/grids-as-sales-for-client/" + bdr + "/" + idToUse + "?format=" + format + "&locale=" + locale);
        this.appFileSaver.openExcel($event, url);
    };
    HomeController.prototype.exportGridAsContact = function ($event, format) {
        var idToUse = this.selectedSnapshotId;
        var locale = this._$i18next.options.lng.substring(0, 2);
        var url = this.supportService.addSelectedUserToUrlIfAny("export/grids-as-contact/" + idToUse + "?format=" + format + "&locale=" + locale);
        this.appFileSaver.openExcel($event, url);
    };
    HomeController.prototype.userAsExportAsContactRole = function () {
        var user = this.ezUsers;
        return user.roles.indexOf("api.sgm-payfx-back_EXPORT_AS_CONTACT") > -1;
    };
    /**
     * Force quotes order EUR, GBP, USD, CHF, CAD
     * @param {{[p: string]: IEnhancedQuote[]}} quotesModel
     * @returns {{[p: string]: IEnhancedQuote[]}}
     */
    HomeController.prototype.orderQuotesModel = function (quotesModel) {
        var _this = this;
        var orderedQuotesModel = {};
        var orderedPivotCcy = ["EUR", "GBP", "USD", "CHF", "CAD", "SGD"];
        orderedPivotCcy.forEach(function (pivot) {
            var listOfCCy = quotesModel[pivot];
            if (listOfCCy && listOfCCy.length > 0) {
                _this.availablePivots.push(pivot);
                orderedQuotesModel[pivot] = quotesModel[pivot];
            }
        });
        Object.keys(quotesModel)
            .sort()
            .forEach(function (key) {
            var value = quotesModel[key];
            orderedQuotesModel[key] = value;
        });
        return orderedQuotesModel;
    };
    HomeController.prototype.updateDisplayedSnapshot = function () {
        var _this = this;
        this.quotesModel = {};
        if (this.selectedSnapshotWrapper) {
            this.selectedSnapshotWrapper.snapshot.quotes.forEach(function (quote) {
                var eQuote = _this.snapshotService.enhanceQuote(quote);
                // Build margedQuotes Map
                if (!_this.quotesModel[eQuote.leftCurrency]) {
                    _this.quotesModel[eQuote.leftCurrency] = [];
                }
                _this.quotesModel[eQuote.leftCurrency].push(eQuote);
            });
            // force order
            this.quotesModel = this.orderQuotesModel(this.quotesModel);
            // Select a pivot currency
            this.selectedPivotCurrency = Object.keys(this.quotesModel)[0];
            this.selectDefaultQuote();
        }
    };
    HomeController.prototype.onGridSelection = function () {
        this.updateDisplayedSnapshot();
    };
    // On selection of a pivot currency (init and html)
    HomeController.prototype.selectDefaultQuote = function () {
        var _this = this;
        var availableQuotes = this.quotesModel[this.selectedPivotCurrency];
        if (availableQuotes) {
            if (!this.selectedQuote) {
                this._$scope.$broadcast("instrumentSelected", availableQuotes[0]);
            }
            else {
                var foundQuote = availableQuotes.find(function (quote) {
                    return quote.rightCurrency === _this.selectedQuote.rightCurrency;
                });
                this._$scope.$broadcast("instrumentSelected", foundQuote ? foundQuote : availableQuotes[0]);
            }
        }
    };
    HomeController.prototype.calculatorExceedEuroAmountMessage = function (calcAmount) {
        var _this = this;
        var euroQuote = this.quotesModel["EUR"];
        var foundQuote = euroQuote.find(function (quote) {
            return quote.rightCurrency === _this.selectedQuote.rightCurrency;
        });
        var euroCurrency = foundQuote ? foundQuote : euroQuote[0];
        var euroAmount = calcAmount / euroCurrency.value;
        if (euroAmount > 300000) {
            this.exceedEuroAmount = true;
        }
        else
            this.exceedEuroAmount = false;
    };
    // On click on a reference quote row (html)
    HomeController.prototype.selectQuote = function (quote) {
        this._$scope.$broadcast("instrumentSelected", quote);
    };
    HomeController.prototype.openClientModal = function (event) {
        this.initClientModalClients();
        this.currentClientIndex = 0;
        this.clientModalApi.show(event);
    };
    HomeController.prototype.regenerateGrid = function (event) {
        var _this = this;
        this.isRegeneratingGrid = true;
        this.snapshotService
            .regenerateGrid()
            .then(function () {
            _this.notificationService.add(_this._$i18next("common:success"), _this._$i18next("home:regenerationSuccessfully"), "success", 5000);
            _this.loadPage();
        })
            .catch(function (err) {
            _this.notificationService.add(_this._$i18next("common:error"), _this._$i18next("home:cannotRegenerateGrid"), "danger", 5000);
        })
            .finally(function () {
            _this.isRegeneratingGrid = false;
        });
    };
    HomeController.prototype.initClientModalClients = function () {
        var _this = this;
        if (!this.clients) {
            this.clients = this.clientService
                .getClientResource()
                .query(function (clients) {
                _this.filteredClients = _this._$filter("orderBy")(clients, "cambistName");
            });
        }
        else {
            this.filteredClients = this._$filter("orderBy")(this.clients, "cambistName");
        }
    };
    HomeController.prototype.initQuoteTimeClients = function (clients) {
        this.quoteTimeClients["AM_02"] = this.getQuoteTimeClients(clients, "AM_02");
        this.quoteTimeClients["AM_08"] = this.getQuoteTimeClients(clients, "AM_08");
        this.quoteTimeClients["PM_12"] = this.getQuoteTimeClients(clients, "PM_12");
        this.quoteTimeClients["PM_02"] = this.getQuoteTimeClients(clients, "PM_02");
        this.quoteTimeClients["PM_05"] = this.getQuoteTimeClients(clients, "PM_05");
    };
    HomeController.prototype.getQuoteTimeClients = function (clients, quoteTime) {
        return clients.filter(function (client) {
            return client.quoteTimes.findIndex(function (clientQuoteTime) { return clientQuoteTime === quoteTime; }) !== -1;
        });
    };
    // Action button on clientModal
    HomeController.prototype.chooseClient = function (client) {
        this.selectedClient = client;
        this.shouldExportAsClient = true;
        this.selectClient(client);
        this.clientModalApi.hide();
    };
    HomeController.prototype.unchooseClient = function (event) {
        this.selectedClient = null;
        this.shouldExportAsClient = false;
        this.loadPage();
    };
    // Action button on newGridModal
    HomeController.prototype.createNewGrid = function () {
        var _this = this;
        this.createNewGridLoading = true;
        this.snapshotService
            .createRaws()
            .then(function (response) {
            // Display created raws.
            _this.loadPage();
        })
            .catch(function () {
            _this.notificationService.add(_this._$i18next("common:error"), _this._$i18next("home:cannotCreateSnapshot"), "danger", 5000);
        })
            .finally(function () {
            _this.createNewGridLoading = false;
            _this.newGridModalApi.hide();
        });
    };
    HomeController.prototype.initNotifyAccountModalClients = function () {
        var _this = this;
        if (!this.clients) {
            this.clients = this.clientService
                .getClientResource()
                .query(function (clients) {
                _this.filteredClients = _this._$filter("orderBy")(clients, "cambistName");
                _this.initQuoteTimeClients(_this.filteredClients);
            });
        }
        else {
            this.filteredClients = this._$filter("orderBy")(this.clients, "cambistName");
            this.initQuoteTimeClients(this.filteredClients);
        }
    };
    HomeController.prototype.openNotifyAccountModal = function (event) {
        this.quoteTimeClients = {};
        this.initNotifyAccountModalClients();
        this.nbClientsForNotif = 0;
        this.selectedClientsForNotif = {};
        this.currentClientIndex = 0;
        this.notifyAccountModalApi.show(event);
    };
    HomeController.prototype.filterClient = function (query) {
        this.currentClientIndex = 0;
        this.filteredClients = this._$filter("orderBy")(this._$filter("filter")(this.clients, query), "cambistName");
    };
    // Action buttons 'AM_02, AM_08, PM_12, PM_02 and PM_05' on notifyAccountModal
    HomeController.prototype.selectAllClientsToNotifAtQuoteTime = function (quoteTime, state) {
        var _this = this;
        this.quoteTimeClients[quoteTime].forEach(function (client) { return (_this.selectedClientsForNotif[client.bdr] = state); });
        this.nbClientsForNotif = Object.keys(this.selectedClientsForNotif).filter(function (key) { return _this.selectedClientsForNotif[key]; }).length;
    };
    // Action button 'send' on notifyAccountModal
    HomeController.prototype.notifyAccounts = function () {
        var _this = this;
        var bdrs = Object.keys(this.selectedClientsForNotif)
            .filter(function (key) {
            return _this.selectedClientsForNotif[key];
        })
            .map(Number);
        this.snapshotService
            .createSnapshotForClients(this.selectedSnapshotWrapper.snapshot.snapshotDateTime, bdrs)
            .then(function () {
            _this.notificationService.add(_this._$i18next("common:success"), _this._$i18next("home:batchSuccessfullyCreated"), "success", 5000);
        })
            .catch(function () {
            _this.notificationService.add(_this._$i18next("common:error"), _this._$i18next("home:cannotCreateBatch"), "danger", 5000);
        })
            .finally(function () {
            _this.notifyAccountModalApi.hide();
        });
    };
    return HomeController;
}());
angular
    .module("app")
    .config(function ($routeProvider) {
    $routeProvider
        .when("/", {
        controller: "HomeController",
        controllerAs: "home",
        templateUrl: "client/features/home/home.html",
        reloadOnSearch: false,
    })
        .otherwise({
        redirectTo: "/",
    });
})
    .controller("HomeController", HomeController);
