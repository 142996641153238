///<reference path="../../../../references.d.ts"/>
var CURRENCY_PAIR_REGEX = /^[A-Z]{3}[/]{0,1}[A-Z]{3}$/;
/**
 * Default Parameters tab controller
 */
var DefaultParametersController = (function () {
    function DefaultParametersController(InstrumentService, InstrumentRefSetService, ClientService, AdminService, NotificationService, $scope, $i18next, $appFileSaver) {
        var _this = this;
        this._$scope = $scope;
        this._$i18next = $i18next;
        this.notificationService = NotificationService;
        this.instrumentService = InstrumentService;
        this.instrumentRefSetService = InstrumentRefSetService;
        this.clientService = ClientService;
        this.adminService = AdminService;
        this.appFileSaver = $appFileSaver;
        this.instruments = this.instrumentService.getInstrumentResource().query(function () {
            _this.adminService.getParameterResource().get(function (result) {
                var params = result;
                _this.availableQuoteFormats = params.reportFormats;
                _this.availablePricingTime = params.quoteTimes;
                _this.adminService.getDefaultParameterResource().get(function (result) {
                    _this.defaultParameters = result;
                    _this.editedPricingTime = _this.adminService.toBooleanMap(_this.defaultParameters.quoteTimes);
                    _this.editedQuoteFormat = _this.adminService.toBooleanMap(_this.defaultParameters.reportFormats);
                    _this.loaded = true;
                }, function () {
                    _this.notificationService.add(_this._$i18next('common:error'), _this._$i18next('admin:account.cannotRetrieveDefaultParamList'), 'danger', 5000);
                    _this.loaded = true;
                });
            }, function () {
                _this.loaded = true;
                _this.notificationService.add(_this._$i18next('common:error'), _this._$i18next('admin:default.cannotRetrieveAvailableParams'), 'danger', 5000);
            });
        }, function () {
            _this.notificationService.add(_this._$i18next('common:error'), _this._$i18next('admin:account.cannotRetrieveInstrumentList'), 'danger', 5000);
            _this.loaded = true;
        });
    }
    DefaultParametersController.prototype.saveEmailNotifications = function () {
        var _this = this;
        this.defaultParametersSaving = true;
        this.defaultParameters.quoteTimes = this.adminService.toStringArray(this.editedPricingTime);
        this.defaultParameters.reportFormats = this.adminService.toStringArray(this.editedQuoteFormat);
        this.adminService.getDefaultParameterResource().save(this.defaultParameters, function () {
            _this.notificationService.add(_this._$i18next('common:success'), _this._$i18next('admin:default.successfullySaved'), 'success', 5000);
            _this.defaultParametersSaving = false;
        }, function () {
            _this.notificationService.add(_this._$i18next('common:error'), _this._$i18next('admin:default.cannotSaveNotif'), 'danger', 5000);
            _this.defaultParametersSaving = false;
        });
    };
    DefaultParametersController.prototype.createInstrument = function (event) {
        var _this = this;
        this.instrumentRefSetService.getAvailableRefSets().then(function (response) {
            _this.availableRefSets = response.data;
            _this.selectedInstrument = null;
            _this.editedInstrument = _this.instrumentService.newInstrument();
            _this.editedInstrument.delta = 0.01;
            _this.editedInstrument.margin = 0.01;
            _this.modalEditInstrument.show(event);
        });
    };
    DefaultParametersController.prototype.editInstrument = function (instrument, event) {
        var _this = this;
        this.instrumentRefSetService.getAvailableRefSets().then(function (response) {
            _this.availableRefSets = response.data;
            _this.selectedInstrument = instrument;
            _this.editedInstrument = angular.copy(_this.selectedInstrument);
            // set the refSet on edited instrument by getting one from the available refsets
            // because we want the drop down to be initialized. The instrument refSet we have by
            // using the angular.copy() is not equals to any 
            var foundRefSet = _this.availableRefSets.find(function (element) { return element.id === _this.editedInstrument.instrumentRefSet.id; });
            _this.editedInstrument.instrumentRefSet = foundRefSet;
            _this.modalEditInstrument.show(event);
        });
    };
    DefaultParametersController.prototype.intrumentAlreadyExists = function (tested) {
        return this.instruments.find(function (value) {
            return value.name === tested.name;
        }) !== undefined;
    };
    DefaultParametersController.prototype.isInstrumentFormValid = function () {
        return CURRENCY_PAIR_REGEX.test(this.editedInstrument.name)
            && this.editedInstrument.name.substring(0, 3) !== this.editedInstrument.name.substring(3)
            && ((!this.editedInstrument.displayedName || this.editedInstrument.displayedName.length === 0) || CURRENCY_PAIR_REGEX.test(this.editedInstrument.displayedName))
            && this.editedInstrument.precision > 0 && this.editedInstrument.precision < 10
            && this.editedInstrument.instrumentRefSet != null
            && (!this.isEditedInstrumentForBloomberg() || (this.isEditedInstrumentForBloomberg() && this.editedInstrument.bloombergTicker != null && 0 < this.editedInstrument.bloombergTicker.length));
    };
    DefaultParametersController.prototype.doEditInstrument = function (event) {
        var _this = this;
        this.modalEditInstrumentLoading = true;
        this.editedInstrument.name = this.editedInstrument.name.replace('/', ''); // In case user write the '/' between currency pair
        if (this.editedInstrument.displayedName && this.editedInstrument.displayedName !== '') {
            this.editedInstrument.displayedName = this.editedInstrument.displayedName.replace('/', ''); // In case user write the '/' between currency pair
        }
        else {
            this.editedInstrument.displayedName = null;
        }
        if (!this.isEditedInstrumentForBloomberg()) {
            this.editedInstrument.bloombergTicker = null;
        }
        if (this.selectedInstrument === null && this.intrumentAlreadyExists(this.editedInstrument)) {
            this.closeEditModal(this._$i18next('admin:default.instrumentAlreadyExistTitle'), this._$i18next('admin:default.instrumentAlreadyExistMessage'), 'danger');
        }
        else {
            this.instrumentService.getInstrumentResource().save(this.editedInstrument, function () {
                if (_this.selectedInstrument === null) {
                    _this.instruments.push(_this.editedInstrument);
                }
                else {
                    angular.copy(_this.editedInstrument, _this.selectedInstrument);
                }
                _this.closeEditModal(_this._$i18next('common:success'), _this._$i18next('admin:default.successfullySavedCcy'), 'success');
            }, function () {
                _this.closeEditModal(_this._$i18next('common:error'), _this._$i18next('admin:default.cannotSaveCcy'), 'danger');
            });
        }
    };
    DefaultParametersController.prototype.closeEditModal = function (title, text, color) {
        this.notificationService.add(title, text, color, 5000);
        this.modalEditInstrumentLoading = false;
        this.modalEditInstrument.hide();
    };
    DefaultParametersController.prototype.deleteInstrument = function (instrument, event) {
        var _this = this;
        this.selectedInstrument = instrument;
        this.selectedInstrumentAccounts = null;
        this.modalDeleteInstrument.show(event);
        this.clientService.getClientsByInstrument(instrument).then(function (response) {
            _this.selectedInstrumentAccounts = response.data;
        }, function () {
            _this.closeDeleteModal(_this._$i18next('common:error'), _this._$i18next('admin:account.cannotRetrieveAccountList'), 'danger');
        });
    };
    DefaultParametersController.prototype.doDeleteInstrument = function () {
        var _this = this;
        this.modalDeleteInstrumentLoading = true;
        this.instrumentService.getInstrumentResource().delete({ name: this.selectedInstrument.name }, function () {
            _this.instruments.splice(_this.instruments.indexOf(_this.selectedInstrument), 1);
            _this.closeDeleteModal(_this._$i18next('common:success'), _this._$i18next('admin:default.successfullyDeleteInstrument'), 'success');
        }, function (error) {
            _this.closeDeleteModal(_this._$i18next('common:error'), _this._$i18next('admin:default.cannotDeleteInstrument'), 'danger');
        });
    };
    DefaultParametersController.prototype.closeDeleteModal = function (title, text, color) {
        this.notificationService.add(title, text, color, 5000);
        this.modalDeleteInstrumentLoading = false;
        this.modalDeleteInstrument.hide();
    };
    DefaultParametersController.prototype.isEditedInstrumentForBloomberg = function () {
        var isEditedInstrumentForBloomberg = this.editedInstrument
            && this.editedInstrument.instrumentRefSet
            && this.editedInstrument.instrumentRefSet.source === 'BLOOMBERG';
        return isEditedInstrumentForBloomberg;
    };
    return DefaultParametersController;
}());
angular.module('app')
    .controller('DefaultParametersController', DefaultParametersController);
