/// <reference path="../../references.d.ts"/>
var EzNotification = (function () {
    function EzNotification(title, text, importance) {
        this.title = title;
        this.text = text;
        this.importance = importance;
    }
    return EzNotification;
}());
/**
 * Wraps EzNotifications for messages to the user
 */
var NotificationService = (function () {
    function NotificationService(ezNotifications, $timeout) {
        this.$timeout = $timeout;
        this.ezNotifications = ezNotifications;
    }
    NotificationService.prototype.add = function (title, text, importance, nbTimeout) {
        var _this = this;
        var notif = this.ezNotifications.add(new EzNotification(title, text, importance));
        if (nbTimeout) {
            this.$timeout(function () {
                _this.ezNotifications.remove(notif.id);
            }, nbTimeout);
        }
    };
    return NotificationService;
}());
angular.module('app').service('NotificationService', NotificationService);
