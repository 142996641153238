///<reference path="../../references.d.ts"/>
var SupportService = (function () {
    function SupportService(extendedHttpService, ezAppScope) {
        this._$http = extendedHttpService;
        this.ezAppScope = ezAppScope;
    }
    SupportService.prototype.getUsersForSupport = function () {
        return this._$http.get("support/user");
    };
    SupportService.prototype.getSelectedUser = function () {
        return window.sessionStorage.getItem("selectedUser");
    };
    SupportService.prototype.setSelectedUser = function (username) {
        window.sessionStorage.setItem("selectedUser", username);
    };
    SupportService.prototype.loadSelectedUserIfAny = function (ezUsers) {
        var username = window.sessionStorage.getItem("selectedUser");
        if (username) {
            this._$http
                .get("support/user/" + username + "/role")
                .then(function (response) {
                if (response.data && response.data.length > 0) {
                    ezUsers.roles = ["api.sgm-payfx-back_NAVIGATE_AS"].concat(response.data);
                }
            });
            this.ezAppScope.ensure("selectedUser", {
                mail: username,
            });
        }
        return username;
    };
    SupportService.prototype.deleteSelectedUserIfAny = function () {
        window.sessionStorage.removeItem("selectedUser");
        delete this.ezAppScope.selectedUser;
    };
    SupportService.prototype.addSelectedUserToUrlIfAny = function (url) {
        var username = window.sessionStorage.getItem("selectedUser");
        if (!username) {
            return url;
        }
        var newUrl = url.indexOf("?") > -1 ? url + "&" : url + "?";
        newUrl += "selectedUser=" + username;
        return newUrl;
    };
    return SupportService;
}());
angular.module("app").service("SupportService", SupportService);
