///<reference path="../../../references.d.ts"/>
var PaginationController = (function () {
    function PaginationController($scope) {
        var _this = this;
        this.nbElementPerPage = parseInt($scope.limit, 10);
        this.nextPages = $scope.nextPages;
        $scope.$watch(function () { return _this.length; }, function () {
            if (_this.length === undefined) {
                return;
            }
            var nbPages = Math.ceil(_this.length / _this.nbElementPerPage);
            _this.pages = new Array(nbPages);
            _this.currentPage = _this.currentIndex / _this.nbElementPerPage;
            _this.firstIndexOnLastPage = (nbPages - 1) * _this.nbElementPerPage;
            _this.showBtnLastPage = _this.hasAllPages === undefined ? true : _this.hasAllPages;
            _this.showSpinner = false;
        });
    }
    PaginationController.prototype.changeIndex = function (indexNumber) {
        if (this.currentIndex !== indexNumber) {
            this.currentIndex = indexNumber;
            this.currentPage = this.currentIndex / this.nbElementPerPage;
            if (this.hasAllPages === false && this.currentIndex === this.firstIndexOnLastPage) {
                this.showSpinner = true;
                this.nextPages();
            }
        }
    };
    PaginationController.prototype.canShowPage = function (index) {
        if (this.pages.length <= 3) {
            return true; // too small length
        }
        if (this.currentPage === 0 && index < 3) {
            return true; // 1st page + 2 pages
        }
        if (this.currentPage === this.pages.length - 1 && index + 3 >= this.pages.length) {
            return true; // last page + 2 previous pages
        }
        // middle pages
        return this.currentPage - index >= -1 && this.currentPage - index <= 1;
    };
    return PaginationController;
}());
angular.module('app')
    .directive('pagination', function () {
    return {
        restrict: 'EA',
        replace: true,
        templateUrl: "client/directives/pagination/pagination.html",
        scope: {
            limit: '@',
            nextPages: '&'
        },
        bindToController: {
            length: '=',
            hasAllPages: '=',
            currentIndex: '='
        },
        controllerAs: 'pgCtl',
        controller: PaginationController
    };
});
