///<reference path="../../references.d.ts"/>

class Instrument implements IInstrument {
  name: string;
  displayedName: string;
  group: string;
  precision: number;
  delta: number;
  margin: number;
  bloombergTicker: string;
  instrumentRefSet: IInstrumentRefSet;

  constructor() {
    this.group = 'DEFAULT';
    this.precision = 4;
  }
}

class InstrumentService implements IInstrumentService {
  private clientResource: ng.resource.IResourceClass<ng.resource.IResource<IInstrument>>;

  constructor($resourceBuilder) {
    this.clientResource = $resourceBuilder.build('instrument/:name');
  }

  public getInstrumentResource(): ng.resource.IResourceClass<ng.resource.IResource<IInstrument>> {
    return this.clientResource;
  }

  public newInstrument(): IInstrument {
    return new Instrument();
  }

}
angular.module('app').service('InstrumentService', InstrumentService);
