///<reference path="../../../../references.d.ts"/>
/**
 * Contact Configuration tab controller
 */
var ContactConfigurationController = (function () {
    function ContactConfigurationController(UserService, ClientService, AdminService, NotificationService, $scope, $i18next, $appFileSaver) {
        var _this = this;
        this.contactValidationMsg = '';
        this.clientIcIds = [];
        this._$scope = $scope;
        this._$i18next = $i18next;
        this.userService = UserService;
        this.adminService = AdminService;
        this.clientService = ClientService;
        this.notificationService = NotificationService;
        this.appFileSaver = $appFileSaver;
        this.users = this.userService.getUserResource().query(function () {
            _this.adminService.getParameterResource().get(function (result) {
                var params = result;
                _this.availableFormats = params.reportFormats;
                _this.loaded = true;
            }, function () {
                _this.notificationService.add(_this._$i18next('common:error'), _this._$i18next('admin:account.cannotRetrieveParameters'), 'danger', 5000);
                _this.loaded = true;
            });
        }, function (error) {
            _this.notificationService.add(_this._$i18next('common:error'), _this._$i18next('admin:contact.cannotRetrieveContactList'), 'danger', 5000);
            _this.loaded = true;
        });
        this.availableClients = this.clientService.getClientResource().query({ 'full': true }, function () {
        }, function (error) {
            _this.notificationService.add(_this._$i18next('common:error'), _this._$i18next('admin:account.cannotRetrieveAccountList'), 'danger', 5000);
        });
    }
    ContactConfigurationController.prototype.getClientIcId = function (client) {
        var _this = this;
        this.clientService.getClientIcId(client.bdr).then(function (response) {
            var found = false;
            var icIdFromService = response.data;
            for (var i = 0; i < _this.clientIcIds.length; i++) {
                if (!found) {
                    found = _this.clientIcIds[i].icId === icIdFromService;
                }
            }
            if (!found) {
                var newClientId = _this.clientService.newClientId();
                newClientId.bdr = client.bdr;
                newClientId.icId = icIdFromService;
                newClientId.cambistName = client.cambistName;
                _this.clientIcIds.push(newClientId);
            }
        }, function (error) {
            _this.notificationService.add(_this._$i18next('common:error'), _this._$i18next('admin:account.cannotRetrieveParameters'), 'danger', 5000);
        });
    };
    // Contact edition methods
    ContactConfigurationController.prototype.createContact = function (event) {
        this.selectedUser = null;
        this.editedUser = this.userService.newUser();
        this.controlThatUserBelongsToClient = true;
        this.editedFormats = {};
        this.modalEditContactCreateMode = true;
        this.modalEditContact.show(event);
    };
    ContactConfigurationController.prototype.computeDefaultReportFormats = function () {
        this.editedFormats = this.adminService.toBooleanMap(this.getClientsReportFormats(this.editedUser));
    };
    ContactConfigurationController.prototype.addClient = function (client) {
        this.getClientIcId(client);
        this.computeDefaultReportFormats();
    };
    ContactConfigurationController.prototype.removeClient = function (client) {
        for (var i = 0; i < this.clientIcIds.length; i++) {
            if (this.clientIcIds[i].bdr === client.bdr) {
                this.clientIcIds.splice(i, 1);
                break;
            }
        }
        this.computeDefaultReportFormats();
    };
    ContactConfigurationController.prototype.getClientsReportFormats = function (user) {
        var reports = [];
        if (user.clients) {
            for (var i = 0; i < user.clients.length; i++) {
                var client = user.clients[i];
                for (var j = 0; j < client.reportFormats.length; j++) {
                    var format = client.reportFormats[i];
                    if (format && reports.indexOf(format) < 0) {
                        reports.push(format);
                    }
                }
            }
        }
        return reports;
    };
    // Called when client email is changed in modal
    ContactConfigurationController.prototype.formatEditedEmail = function () {
        if (this.editedUser && this.editedUser.email) {
            this.editedUser.email = this.editedUser.email.toLowerCase();
        }
    };
    ContactConfigurationController.prototype.editContact = function (user, event) {
        delete user.$rowConfig;
        this.selectedUser = user;
        this.editedUser = angular.copy(this.selectedUser);
        // Initialize checkbox model for modals
        this.editedFormats = this.adminService.toBooleanMap(this.editedUser.reportFormats);
        this.modalEditContactCreateMode = false;
        this.modalEditContact.show(event);
    };
    ContactConfigurationController.prototype.isFormValid = function () {
        return (this.editedUser.email && this.editedUser.clients && this.editedUser.clients.length > 0 && true);
    };
    ContactConfigurationController.prototype.userAlreadyExists = function (testedUser) {
        var exists = false;
        this.users.forEach(function (value) {
            var user = value;
            if (testedUser.email === user.email) {
                exists = true;
            }
        });
        return exists;
    };
    ContactConfigurationController.prototype.doSaveContact = function () {
        var _this = this;
        this.modalEditContactLoading = true;
        // Inject checkbox model into edited objects
        this.editedUser.reportFormats = this.adminService.toStringArray(this.editedFormats);
        var userForm = this.userService.userFormFromUser(this.editedUser);
        if (this.selectedUser === null && this.userAlreadyExists(this.editedUser)) {
            this.closeEditModal(this._$i18next('admin:contact.alreadyExistsTitle'), this._$i18next('admin:contact.alreadyExistsMessage'), 'danger');
        }
        else {
            this.userService.getUserResource().save({ controlThatUserBelongsToClient: this.controlThatUserBelongsToClient }, userForm, function (requestData) {
                _this.editedUser = requestData;
                if (_this.selectedUser === null) {
                    _this.selectedUser = _this.userService.newUser();
                    angular.copy(_this.editedUser, _this.selectedUser);
                    _this.users.push(_this.selectedUser);
                }
                else {
                    angular.copy(_this.editedUser, _this.selectedUser);
                }
                _this.closeEditModal(_this._$i18next('common:success'), _this._$i18next('admin:contact.successfullySaved'), 'success');
            }, function (error) {
                if (_this.modalEditContactCreateMode && error.status > 400 && error.status < 500) {
                    _this.closeEditModal(_this._$i18next('common:error'), _this._$i18next('admin:contact.cannotBeSavedInvalidMail', {
                        'email': _this.editedUser.email,
                        'bdr': _this.getClientsAsString(_this.editedUser)
                    }), 'danger', 10000);
                }
                else {
                    _this.closeEditModal(_this._$i18next('common:error'), _this._$i18next('admin:contact.cannotBeSaved'), 'danger');
                }
            });
        }
    };
    ContactConfigurationController.prototype.closeEditModal = function (title, text, color, timeout) {
        this.contactValidationMsg = '';
        this.modalEditContactLoading = false;
        // on success close modal else show msg on modal panel
        if (color === 'success') {
            this.notificationService.add(title, text, color, timeout ? timeout : 5000);
            this.modalEditContact.hide();
        }
        else {
            this.contactValidationMsg = text;
        }
    };
    // close contact panel
    ContactConfigurationController.prototype.doCancel = function () {
        this.modalEditContact.hide();
        this.contactValidationMsg = '';
    };
    ContactConfigurationController.prototype.getClientsAsString = function (user) {
        var clientsAsString = user.clients.map(function (client) { return client.cambistName + " (" + client.bdr + ")"; }).join(', ');
        return clientsAsString;
    };
    // Contact deletion methods
    ContactConfigurationController.prototype.deleteContact = function (user, event) {
        this.selectedUser = user;
        this.modalDeleteContact.show(event);
    };
    ContactConfigurationController.prototype.doDeleteContact = function () {
        var _this = this;
        this.modalDeleteContactLoading = true;
        this.userService.getUserResource().delete({ email: this.selectedUser.email }, function () {
            _this.users.splice(_this.users.indexOf(_this.selectedUser), 1);
            _this.closeDeleteModal(_this._$i18next('common:success'), _this._$i18next('admin:contact.successfullyDeleted'), 'success');
        }, function (error) {
            _this.closeDeleteModal(_this._$i18next('common:error'), _this._$i18next('admin:contact.cannotBeDeleted'), 'danger');
        });
    };
    ContactConfigurationController.prototype.closeDeleteModal = function (title, text, color) {
        this.notificationService.add(title, text, color, 5000);
        this.modalDeleteContactLoading = false;
        this.modalDeleteContact.hide();
    };
    return ContactConfigurationController;
}());
angular.module('app')
    .controller('ContactConfigurationController', ContactConfigurationController);
