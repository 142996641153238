///<reference path="../../../references.d.ts"/>
var NavigateAsController = (function () {
    function NavigateAsController($location, SupportService) {
        this._$location = $location;
        this.supportService = SupportService;
    }
    NavigateAsController.prototype.selectUser = function () {
        this.supportService.setSelectedUser(this.navigateAsEmail);
        // go to home page
        this._$location.path('/');
        window.location.reload();
    };
    return NavigateAsController;
}());
angular.module('app')
    .directive('navigateContacts', function () {
    return {
        restrict: 'EA',
        replace: true,
        templateUrl: "client/directives/navigateContacts/navigateContacts.html",
        scope: true,
        controllerAs: 'navCtrl',
        controller: NavigateAsController
    };
});
