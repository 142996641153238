///<reference path="../../references.d.ts"/>
/**
 * Show string arrays as a flat comma separated string
 */
angular.module('app')
    .filter('flattenStringArray', function () {
    return function (input) {
        if (angular.isUndefined(input) || input == null) {
            return '';
        }
        if (!angular.isArray(input)) {
            return input;
        }
        var result = '';
        input.forEach(function (part) {
            result = result + part + ', ';
        });
        return result.substring(0, result.length - 2);
    };
}).filter('flattenArray', function () {
    return function (input, field) {
        if (angular.isUndefined(input) || input == null) {
            return '';
        }
        if (!angular.isArray(input)) {
            return input;
        }
        var result = '';
        input.forEach(function (part) {
            result = result + part[field] + ', ';
        });
        return result.substring(0, result.length - 2);
    };
});
