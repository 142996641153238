///<reference path="../references.d.ts"/>
var sgwtConnectInterceptor = function (sgwtConnectProvider) {
    var wrapper = window;
    sgwtConnectProvider.use(wrapper.sgwtConnect, function (_a) {
        var url = _a.url;
        return url.indexOf(wrapper.appConfig.backend_url) === 0;
    });
};
var locationProviderConfigurer = function ($locationProvider) {
    $locationProvider.hashPrefix('');
};
var ezWebConfigurer = function (ezUsersProvider, ezPostAuthenticationBackendProvider) {
    ezPostAuthenticationBackendProvider.configure({
        rememberMeParameter: 'false',
        currentUserUri: window.appConfig.backend_url + 'authentication/current'
    });
    ezUsersProvider.configure('ezPostAuthenticationBackend');
};
var errorHandlerInterceptor = function ($httpProvider) {
    $httpProvider.interceptors.push(function ($location, $q, $window) {
        return {
            responseError: function (response) {
                var status = response.status;
                if (status === 401) {
                    // the actual session has expired. we redirect the user to the login form.
                    if (status === 401) {
                        // the actual session has expired. we redirect the user to the login form.
                        if (response.data.SsoRedirectUrl) {
                            $window.location.replace(response.data.SsoRedirectUrl);
                        }
                    }
                }
                return $q.reject(response);
            }
        };
    });
};
var computeUrl = function ($window) {
    return function (path) {
        return $window.appConfig.backend_url + path;
    };
};
var resourceBuilder = function ($computeUrl, $resource) {
    return {
        build: function (path) {
            return $resource($computeUrl(path));
        }
    };
};
var extendedHttpService = function ($computeUrl, $http) {
    return {
        get: function (url, other) {
            return $http.get($computeUrl(url), other);
        },
        post: function (url, other) {
            return $http.post($computeUrl(url), other);
        },
        head: function (url) {
            return $http.head($computeUrl(url));
        }
    };
};
var piwikConfigurer = function ($window, ezAnalytics, ezUsers) {
    return function (config) {
        // Setup for piwik
        if (config.environment === 'GULP') {
            ezAnalytics.suppress();
        }
        else {
            ezUsers.get(function () {
                $window._paq = $window._paq || [];
                $window._paq.push(['requireCookieConsent']); // That must be track before the trackPageView.
                $window._paq.push(['trackPageView']);
                $window._paq.push(['setUserId', ezUsers.email]);
                ezAnalytics.start(config.piwik_url, config.site_id);
                listenCookiesUserAgreement($window);
            });
        }
    };
};
var listenCookiesUserAgreement = function ($window) {
    $window.didomiOnReady = $window.didomiOnReady || [];
    $window.didomiOnReady.push(function () {
        var widget = document.querySelector('sgwt-mini-footer');
        widget.addEventListener('sgwt-mini-footer--user-consent-changed', function (event) {
            // You get all the details of ALL user consents from the event detail property:      
            // You can also check the consent through our helper functions:
            widget.getUserConsentOnMatomo().then(function (consent) { return updateMatomoUserAcceptance($window, consent); });
        });
    });
};
var updateMatomoUserAcceptance = function ($window, consent) {
    if (consent) {
        $window._paq.push(['rememberCookieConsentGiven', 4320]); // 4320 = retention period of the cookie for 6 month
        $window._paq.push(['setCustomDimension', 10, true]);
    }
    else {
        $window._paq.push(['setCustomDimension', 10, false]);
        $window._paq.push(['forgetCookieConsentGiven']);
    }
};
var appFileSaver = function (FileSaver, extendedHttpService) {
    return {
        openExcel: function ($event, url) {
            // Prevent default behavior when clicking a link
            $event.preventDefault();
            extendedHttpService.get(url, { responseType: 'arraybuffer' })
                .then(function (response) {
                var blob = new Blob([response.data], { type: 'application/zip' });
                var left = 'attachment; filename="';
                var dispo = response.headers('content-disposition');
                FileSaver.saveAs(blob, dispo.substring(left.length, dispo.length - '"'.length));
            });
        }
    };
};
window.ezweb.start() //
    .config(ezWebConfigurer)
    .config(['$locationProvider', locationProviderConfigurer])
    .config(sgwtConnectInterceptor)
    .config(errorHandlerInterceptor)
    .factory('$computeUrl', computeUrl)
    .factory('$resourceBuilder', resourceBuilder)
    .factory('extendedHttpService', extendedHttpService)
    .factory('$piwikConfigurer', piwikConfigurer)
    .factory('$appFileSaver', appFileSaver)
    .run(function (ezAppScope, $piwikConfigurer, $window) {
    var config = $window.appConfig;
    ezAppScope.ensure('envconfig', config);
    $piwikConfigurer(config);
});
