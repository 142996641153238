///<reference path="../../../references.d.ts"/>

class CheckboxModel {
  base: { [key: string]: boolean };
  quote: { [key: string]: boolean };

  constructor() {
    this.base = {};
    this.quote = {};
  }
}

class CcyPickerController {
  adminService: IAdminService;

  ngData: IInstrument[]; // list of reference instruments sent by parent
  creationMode: boolean; // retreived from directive params
  originalPairs: string[]; // original value of ngModel on init

  // Calculated reference model
  baseCurrencies: string[];
  quoteCurrencies: string[];
  availablePairs: string[];

  // Analysis messages model
  pairsToBeAdded: string[];
  pairsToBeRemoved: string[];
  showMissingCurrencyMessage: boolean;

  // Interaction model
  checkboxModel: CheckboxModel;

  // Return value containers
  ngModel: string[];

  // default base currencies
  baseCurrenciesValues: string[] = ['EUR', 'CHF', 'GBP'];



  constructor(AdminService: IAdminService, $location: ng.ILocationService, $scope: ng.IScope) {
    this.adminService = AdminService;

    // When new instrument list is passed to the directive
    // Initialise ccyPicker Model
    $scope.$watch(() => { return this.ngData; }, () => {
      this.baseCurrencies = [];
      this.quoteCurrencies = [];
      this.availablePairs = [];
      this.checkboxModel = new CheckboxModel();
      if (this.ngData) {
        // Init Reference Model
        this.ngData.forEach((instrument: IInstrument) => {
          let base: string = instrument.name.substring(0, 3);
          let quote: string = instrument.name.substring(3, 6);
          if (this.baseCurrencies.indexOf(base) < 0) {
            this.baseCurrencies.push(base);
          }
          if (this.quoteCurrencies.indexOf(quote) < 0) {
            this.quoteCurrencies.push(quote);
          }
          this.availablePairs.push(instrument.name);
        });

        if (this.creationMode) { // creation mode, apply the default selection
          this.setDefaultSettings();
        }
        else { // edition mode, load checkboxModel from existing ngModel
          this.originalPairs = this.ngModel;
          if (this.ngModel) {
            this.ngModel.forEach((instrument: string) => {
              let base: string = instrument.substring(0, 3);
              let quote: string = instrument.substring(3, 6);
              this.checkboxModel.base[base] = true;
              this.checkboxModel.quote[quote] = true;
            });
          }
        }
      }
    });

    // Apply checkboxModel to ngModel every time it changes
    $scope.$watch(() => { return this.checkboxModel; }, () => {
      // adapt checkboxModel
      this.showMissingCurrencyMessage = false;
      this.quoteCurrencies.forEach((quoteCur: string) => {
        if (!this.isQuoteCurrencyAvailable(quoteCur)) {
          this.checkboxModel.quote[quoteCur] = false;
        }
      });

      // apply checkboxModel
      this.ngModel = [];
      this.ngData.forEach((instrument: IInstrument) => {
        let base: string = instrument.name.substring(0, 3);
        let quote: string = instrument.name.substring(3, 6);
        if (this.checkboxModel.base[base] && this.checkboxModel.quote[quote]) {
          this.ngModel.push(instrument.name);
        }
      });
      /* Generate analysis messages model */
      if (this.originalPairs) {
        // Find added pairs
        this.pairsToBeAdded = [];
        this.ngModel.forEach((pair: string) => {
          if (this.originalPairs.indexOf(pair) < 0) {
            this.pairsToBeAdded.push(pair);
          }
        });
        // Find removed pairs
        this.pairsToBeRemoved = [];
        this.originalPairs.forEach((pair: string) => {
          if (this.ngModel.indexOf(pair) < 0) {
            this.pairsToBeRemoved.push(pair);
          }
        });
      }
    }, true);

  }

  public selectAll() {
    this.checkboxModel = new CheckboxModel;
    this.baseCurrencies.forEach((baseCur: string) => {
      this.checkboxModel.base[baseCur] = true;
    });
    this.quoteCurrencies.forEach((quoteCur: string) => {
      this.checkboxModel.quote[quoteCur] = true;
    });
  }

  public setDefaultSettings(): void {
    this.checkboxModel = new CheckboxModel;
    this.baseCurrencies.forEach((baseCur: string) => {
      this.checkboxModel.base[baseCur] = this.baseCurrenciesValues.indexOf(baseCur) >= 0;
    });
    this.quoteCurrencies.forEach((quoteCur: string) => {
      this.checkboxModel.quote[quoteCur] = true;
    });
  }

  public selectNone() {
    this.checkboxModel = new CheckboxModel;
  }

  public isQuoteCurrencyAvailable(quote: string): boolean {
    return Object.keys(this.checkboxModel.base).every((baseCur: string) => {
      if (this.checkboxModel.base[baseCur] && quote !== baseCur) {
        if (this.availablePairs.indexOf(baseCur + quote) < 0) {
          this.showMissingCurrencyMessage = true;
          return false;
        }
      }
      return true;
    });
  }
}

angular.module('app')
  .directive('ccyPicker', (): ng.IDirective => {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'ccyPicker.html',
      scope: {},
      bindToController: {
        ngData: '=',
        ngModel: '=',
        creationMode: '='
      },
      controllerAs: 'ccy',
      controller: CcyPickerController
    };
  });
