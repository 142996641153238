///<reference path="../../../references.d.ts"/>

class MainLayoutController {}

class HeaderController {
  private _$location: ng.ILocationService;
  private ezUsers: ezWeb.auth.IEzUsersService;
  public ezAppScope: ezWeb.core.IEzAppScopeFactory;
  private ezI18nService: any;
  private supportService: ISupportService;
  public navigateAsAttribute: undefined | "whatever"; // weird indeed but "'navigate-as' attribute will enable the link 'navigate as' but does not care about its value."
  public currentLanguage: string;
  public environmentName: undefined | string;
  public selectedUser: any;

  constructor(
    $scope: ng.IScope,
    ezI18nService: any,
    $i18next: any,
    $location: ng.ILocationService,
    ezUsers: ezWeb.auth.IEzUsersService,
    SupportService: ISupportService,
    ezAppScope: ezWeb.core.IEzAppScopeFactory
  ) {
    this._$location = $location;
    this.ezUsers = ezUsers;
    this.ezAppScope = ezAppScope;
    this.ezI18nService = ezI18nService;
    this.supportService = SupportService;
    this.supportService.loadSelectedUserIfAny(ezUsers);
    this.currentLanguage = $i18next.options.lng;

    if (this.supportService.getSelectedUser()) {
      this.selectedUser = {
        name: this.supportService.getSelectedUser(),
      };
    }

    let modalListener = $scope.$watchCollection(
      () => [this.ezUsers.connected],
      () => {
        let user = this.ezUsers as any;
        // current environment
        let envName: string = window.sessionStorage.getItem("environment");
        this.environmentName = envName === "production" ? undefined : envName;
        this.navigateAsAttribute =
          user.roles.indexOf("api.sgm-payfx-back_NAVIGATE_AS") > -1 ||
          user.roles.indexOf("api.sgm-payfx-back_NAVIGATE_AS_CLIENT") > -1
            ? "whatever"
            : undefined;
        const accountCenter = document.querySelector("#my-account-center");
        if (accountCenter && this.navigateAsAttribute) {
          accountCenter.setAttribute("navigate-as", this.navigateAsAttribute);
        }
      }
    );
  }

  changeLanguage = function (event: any) {
    this.currentLanguage = event.detail.language;
    this.ezI18nService.changeLanguage(event.detail.language);
  };

  startNavigateAs = function () {
    this.navigateAsModal.show();
  };

  stopNavigateAs = function () {
    this.supportService.setSelectedUser(undefined);
    this.supportService.deleteSelectedUserIfAny();
    this.selectedUser = {
      name: undefined,
    };
    // go to home page
    this._$location.path("/");
    window.location.reload();
  };
}

class FooterController {
  public legalNotices: any;

  constructor($scope: ng.IScope, $i18next: any) {
    let en = "'en':[]";
    let fr = "'fr':[]";
    this.legalNotices = "{" + en + "," + fr + "}";
  }
}

angular
  .module("app")
  .config(function (ezLayoutProvider: ezWeb.layout.IEzLayoutProvider): void {
    let layoutName: string = "main";
    ezLayoutProvider.register(layoutName, { templateUrl: "./main.html" });
    ezLayoutProvider.setDefault(layoutName);
  })
  .controller("MainLayoutController", MainLayoutController)
  .controller("HeaderController", HeaderController)
  .controller("FooterController", FooterController);
