///<reference path="../../references.d.ts"/>

/**
 * directive used to collapse mobile-size bootstrap menus by simulating a click on the toggle button
 * 
 * usage : <a menu-collapser> </a>
 */
interface MenuCollapser extends ng.IScope {
  fileread: File;
}

angular.module('app')
  .directive('menuCollapser', (): ng.IDirective => {
    return {
      restrict: 'A',
      link: function (scope: MenuCollapser, element: ng.IAugmentedJQuery, attributes: ng.IAttributes, formController: ng.IFormController) {
        $(element).on('click', () => {
          if ('matchMedia' in window) { // Compatibility detection
            if (window.matchMedia('(max-width:767px)').matches) {
              $('.navbar-toggle').click();
            }
          }
        });
      }
    };
  });
